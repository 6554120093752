import { ReactNode, useCallback, useMemo } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from "../..";
import { Bond, Side } from '../data/dataProvider';
import { Column, Sort, TableProps, TableSortIcon } from '../components/table/table';
import { getBpsDeltaAsymptoticSpectrumBackground, getPositionValueAsymptoticSpectrumBackground, getPriceAsymptoticSpectrumBackground } from '../components/spectrum';
import { CusipCell } from '../components/data/cusipCell';
import { AlertCell } from '../components/data/alertCell';
import { AlertHeaderCell } from '../components/data/alertHeaderCell';
import { getLinkColumnConfig } from '../components/data/table.utils';
import { ColumnsOrderConfig, NO_POSITIONS_FOR_FILTERS, PortfolioColumn } from './portfolio.constants';
import { formatAmountOutstanding, getNumber, numericFormatter, priceOrString } from '@/utils/number.utils';
import { InferenceResult } from '@hooks/data/useSimpleInferenceData';
import { useUiMode } from '@hooks/useUiMode';
import { calculateBps, getColumnTitle, getPositionPrice, getPositionValue } from './portfolio.utils';
import { TableWithUserPreferences } from '@components/table/tableWithUserPreferences';
import { usePreviousMs } from '@components/filters/hooks/useFilters';
import { createNumberSort, createStringSort, sortByMaturity } from '@/utils/sort.utils';
import { PercentilesInfoCellContent } from '../components/data/percentilesInfoCellContent';
import { PriceType } from '@/types/types';
import { Position } from '../data/portfolios';
import { PositionSizeInput } from './components/positionSizeInput';
import clsx from 'clsx';
import { POSITION_BPS_FORMAT, POSITION_SIZE_FORMAT, POSITION_VALUE_FORMAT } from '@/constants';

const tdCss = (selected: boolean) => classNames(selected ? 'bg-[#5D5F9D77]' : '', 'px-[0.3125rem] first:rounded-l-[0.625rem] last:rounded-r-[0.625rem] group-hover:bg-[#5D5F9D] group-hover:text-[#FBFBFD]');

const header = (name: ReactNode, sort?: Sort, background?: string, color: string = 'text-[#EDEDF4]') =>
  <div className="border-[#5D5F9D] border-b-[0.0625rem] h-full px-[0.3125rem] w-full">
    <div className={clsx('flex flex-col h-full items-start justify-center px-[0.625rem] py-[0.625rem] rounded-t-[0.625rem] text-[0.875rem] text-start', background, color)}>
      <div className="gap-[0.4375rem] grid grid-cols-[1fr,auto] place-items-center">
        <div className="whitespace-nowrap font-medium">{name}</div>
        <TableSortIcon sort={sort} />
      </div>
    </div>
  </div>

const positionHeader = (name: ReactNode, sort?: Sort) => {
  return header(name, sort, 'bg-[#1F2034]', '#FBFBFD');
}

const cell = (contents: ReactNode, selected: boolean, background?: string) =>
  <div className={classNames(background && !selected ? `${background} group-hover:bg-[transparent]` : '', 'flex flex-col h-full justify-center px-[0.625rem] py-[1rem] text-[0.875rem] whitespace-nowrap')}>
    {contents}
  </div>

const positionCell = (contents: ReactNode, selected: boolean, bg = 'bg-[#1F2034]') => {
  return cell(contents, selected, bg);
}

type Props = {
  inferenceResult: InferenceResult<Position & Bond>;
  linkedFigis: Set<string>,
  positions: (Position & Bond)[],
  remove: (figiSet: Set<string>) => void,
  selectBond: (bond: Bond) => void,
  selectedBond?: (Position & Bond) | null,
  setLinkedFigis: React.Dispatch<React.SetStateAction<Set<string>>>
  tableRef?: TableProps<Position & Bond, PortfolioColumn>['tableRef'];
  onPositionChange?: (positionUpd: Partial<Position> & { figi: string }) => void;
  portfolioNAV: number;
  readonly?: boolean;
}

const PortfolioTable = ({
  inferenceResult,
  linkedFigis,
  positions,
  remove,
  selectBond,
  selectedBond,
  setLinkedFigis,
  tableRef,
  onPositionChange,
  portfolioNAV,
  readonly,
}: Props) => {
  const { getFormattedPreviousDate } = usePreviousMs();
  const previousString = getFormattedPreviousDate();
  const gct = useCallback((c: PortfolioColumn) => getColumnTitle(previousString)(c), [previousString]);

  const { isIgUiMode, isHyUiMode } = useUiMode();
  const { data, createDataSort } = inferenceResult;

  const columns: Column<Position & Bond, PortfolioColumn>[] = useMemo(
    () => {
      const columns: Column<Position & Bond, PortfolioColumn>[] = [
        {
          id: PortfolioColumn.Alert,
          printable: false,
          Cell: (b, { selected }) => cell(<AlertCell cusip={b.cusip} />, selected),
          Header: () => header(<AlertHeaderCell />),
          tdCss,
          draggable: false,
        },
        {
          id: PortfolioColumn.Position,
          getValue: b => b.size,
          Cell: (b, { selected, value }) => {
            const content = (
              <PositionSizeInput
                positionSize={value as number}
                onChange={(size) => {
                  onPositionChange?.({ figi: b.figi, size });
                }}
                displayType={readonly ? 'text' : 'input'}
              />
            )

            return positionCell(content, selected)
          },
          Header: sort => positionHeader(gct(PortfolioColumn.Position), sort),
          sort: createNumberSort('size'),
          tdCss,
          onCellClick: (e) => {
            e.stopPropagation();
          }
        },
        {
          id: PortfolioColumn.PositionValue,
          getValue: b => getPositionValue(b, data),
          Cell: (b, { selected, value }) => positionCell(numericFormatter(value as number, POSITION_VALUE_FORMAT), selected),
          Header: sort => positionHeader(gct(PortfolioColumn.PositionValue), sort),
          sort: (a, b) => {
            const aValue = getPositionValue(a, data);
            const bValue = getPositionValue(b, data);
            return aValue - bValue;
          },
          tdCss,
        },
        {
          id: PortfolioColumn.PositionValueChange,
          getValue: b => {
            return getPositionValue(b, data, true);
          },
          Cell: (b, { selected, value }) => positionCell(
            numericFormatter(value as number, POSITION_VALUE_FORMAT),
            selected,
            getPositionValueAsymptoticSpectrumBackground(value as number, 'light')
          ),
          Header: sort => positionHeader(gct(PortfolioColumn.PositionValueChange), sort),
          sort: (a, b) => {
            const aValue = getPositionValue(a, data, true);
            const bValue = getPositionValue(b, data, true);
            return aValue - bValue
          },
          tdCss,
        },
        {
          id: PortfolioColumn.PositionBPs,
          getValue: b => {
            const value = getPositionValue(b, data);
            return calculateBps(value, portfolioNAV)
          },
          Cell: (b, { selected, value }) => positionCell(numericFormatter(value as number, POSITION_BPS_FORMAT), selected),
          Header: sort => positionHeader(gct(PortfolioColumn.PositionBPs), sort),
          sort: (a, b) => {
            const aValue = getPositionValue(a, data);
            const bValue = getPositionValue(b, data);
            return calculateBps(aValue, portfolioNAV) - calculateBps(bValue, portfolioNAV)
          },
          tdCss,
        },
        {
          id: PortfolioColumn.PositionBPsChange,
          getValue: b => {
            const value = getPositionValue(b, data, true);
            return calculateBps(value, portfolioNAV)
          },
          Cell: (b, { selected, value }) => positionCell(
            numericFormatter(value as number, POSITION_BPS_FORMAT),
            selected,
            getBpsDeltaAsymptoticSpectrumBackground(value as number, 'light')
          ),
          Header: sort => positionHeader(gct(PortfolioColumn.PositionBPsChange), sort),
          sort: (a, b) => {
            const aValue = getPositionValue(a, data, true);
            const bValue = getPositionValue(b, data, true);
            return calculateBps(aValue, portfolioNAV) - calculateBps(bValue, portfolioNAV)
          },
          tdCss,
        },
        {
          id: PortfolioColumn.Cusip,
          getValue: b => b.cusip,
          Cell: (b, { selected, value }) => cell(<CusipCell cusip={value as string} />, selected),
          Header: sort => header(gct(PortfolioColumn.Cusip), sort),
          sort: (a, b) => a['cusip'] > b['cusip'] ? 1 : a['cusip'] < b['cusip'] ? -1 : 0,
          tdCss
        },
        {
          id: PortfolioColumn.Ticker,
          getValue: b => b.ticker,
          Cell: (b, { selected, value }) => cell(value, selected),
          Header: sort => header(gct(PortfolioColumn.Ticker), sort),
          sort: (a, b) => a['ticker'] > b['ticker'] ? 1 : a['ticker'] < b['ticker'] ? -1 : 0,
          tdCss
        },
        {
          id: PortfolioColumn.Coupon,
          getValue: b => b['coupon'].toFixed(3),
          Cell: (b, { selected, value }) => cell(`${value}%`, selected),
          Header: sort => header(gct(PortfolioColumn.Coupon), sort),
          sort: (a, b) => a['coupon'] - b['coupon'],
          tdCss
        },
        {
          id: PortfolioColumn.Maturity,
          getValue: b => b.maturity,
          Cell: (b, { selected, value }) => cell(value, selected),
          Header: sort => header(gct(PortfolioColumn.Maturity), sort),
          sort: sortByMaturity,
          tdCss
        },
        {
          id: PortfolioColumn.SAndPRating,
          getValue: b => b.rating,
          Cell: (b, { selected }) => cell(b['rating'] || '-', selected),
          Header: sort => header(gct(PortfolioColumn.SAndPRating), sort),
          sort: createStringSort('rating'),
          tdCss
        },
        {
          id: PortfolioColumn.Series,
          getValue: b => b.series,
          Cell: (b, { selected, value }) => cell(value || '-', selected),
          Header: sort => header(gct(PortfolioColumn.Series), sort),
          sort: (a, b) => a['series'] > b['series'] ? 1 : a['series'] < b['series'] ? -1 : 0,
          tdCss
        },
        {
          id: PortfolioColumn.Tenor,
          getValue: b => {
            const priceType = isIgUiMode ? PriceType.Spread : PriceType.Price;
            const tenor = data[b.figi][Side.bid][priceType].tenor;
            return typeof tenor === 'number' ? `${tenor}Y` : '-';
          },
          Cell: (b, { selected, value }) => cell(value, selected),
          Header: sort => header(gct(PortfolioColumn.Tenor), sort),
          sort: isIgUiMode
            ? createDataSort(Side.bid, 'spread', 'tenor')
            : createDataSort(Side.bid, 'price', 'tenor'),
          tdCss
        },
        {
          id: PortfolioColumn.AmountOutstanding,
          getValue: b => formatAmountOutstanding(b.amountOutstanding),
          Cell: (b, { selected, value }) => cell(value, selected),
          Header: sort => header(gct(PortfolioColumn.AmountOutstanding), sort),
          sort: createNumberSort('amountOutstanding'),
          tdCss
        },
        {
          id: PortfolioColumn.Percentiles,
          printable: false,
          Cell: (b, { selected }) => cell(
            <PercentilesInfoCellContent data={data[b.figi]} />,
            selected,
          ),
          Header: sort => header(gct(PortfolioColumn.Percentiles), sort),
          onCellClick: e => e.stopPropagation(),
          tdCss,
        },
        {
          id: PortfolioColumn.BidSpd,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.current),
          getValue: b => data[b.figi][Side.bid].spread.currentString,
          Cell: (b, { selected, value }) => cell(value, selected, isIgUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
          Header: sort => header(gct(PortfolioColumn.BidSpd), sort, isIgUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'spread', 'current'),
          tdCss,
          printColumns: [
            {
              id: PortfolioColumn.BidSpdTime,
              getValue: (b) => data[b.figi][Side.bid].spread.currentTime,
            }
          ],
        },
        {
          id: PortfolioColumn.OfferSpd,
          getPrintValue: b => priceOrString(data[b.figi][Side.offer].spread.current),
          getValue: b => data[b.figi][Side.offer].spread.currentString,
          Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#0A0B11]'),
          Header: sort => header(gct(PortfolioColumn.OfferSpd), sort, 'bg-[#0A0B11]'),
          sort: createDataSort(Side.offer, 'spread', 'current'),
          tdCss,
        },
        {
          id: PortfolioColumn.BidSpdChange,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.diff),
          getValue: b => data[b.figi][Side.bid].spread.diffString,
          Cell: (b, { selected, value }) => cell(value, selected, getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].spread.diff, 'light', 'spread')),
          Header: sort => header(gct(PortfolioColumn.BidSpdChange), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'spread', 'diff'),
          tdCss
        },
        {
          id: PortfolioColumn.BYTM,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.current),
          getValue: b => data[b.figi][Side.bid].ytm.currentString,
          Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(PortfolioColumn.BYTM), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'ytm', 'current'),
          tdCss,
        },
        {
          id: PortfolioColumn.BYTMChange,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.diff),
          getValue: b => data[b.figi][Side.bid].ytm.diffString,
          Cell: (b, { selected, value }) => cell(value, selected, getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].ytm.diff, 'light', 'ytm')),
          Header: sort => header(gct(PortfolioColumn.BYTMChange), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'ytm', 'diff'),
          tdCss
        },
        {
          id: PortfolioColumn.Price,
          getPrintValue: b => priceOrString(getPositionPrice(b, data, 'current')),
          getValue: b => getPositionPrice(b, data, 'current', true),
          Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#0A0B11]'),
          Header: sort => header(gct(PortfolioColumn.Price), sort, 'bg-[#0A0B11]'),
          sort: (a, b) => {
            const aPrice = getNumber(getPositionPrice(a, data, 'current'))
            const bPrice = getNumber(getPositionPrice(b, data, 'current'))
            return aPrice - bPrice;
          },
          tdCss,
          printColumns: [
            {
              id: PortfolioColumn.PriceTime,
              getValue: (b) => data[b.figi][Side.bid].price.currentTime,
            }
          ],
        },
        {
          id: PortfolioColumn.PriceChange,
          getPrintValue: b => priceOrString(getPositionPrice(b, data, 'diff')),
          getValue: b => getPositionPrice(b, data, 'diff', true),
          Cell: (b, { selected, value }) => cell(value, selected, getPriceAsymptoticSpectrumBackground(getPositionPrice(b, data, 'diff'), 'light', 'price')),
          Header: sort => header(gct(PortfolioColumn.PriceChange), sort, 'bg-[#484A7A]'),
          sort: (a, b) => {
            const aPrice = getNumber(getPositionPrice(a, data, 'diff'));
            const bPrice = getNumber(getPositionPrice(b, data, 'diff'));

            return aPrice - bPrice;
          },
          tdCss
        },
        {
          id: PortfolioColumn.PricePrev,
          getPrintValue: b => priceOrString(getPositionPrice(b, data, 'prev')),
          getValue: b => getPositionPrice(b, data, 'prev', true),
          Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(PortfolioColumn.BidPxPrev), sort, 'bg-[#484A7A]'),
          sort: (a, b) => {
            const aPrice = getNumber(getPositionPrice(a, data, 'prev'))
            const bPrice = getNumber(getPositionPrice(b, data, 'prev'))
            return aPrice - bPrice;
          },
          tdCss,
          printColumns: [
            {
              id: PortfolioColumn.BidPxPrevTime,
              getValue: (b) => data[b.figi][Side.bid].price.prevTime,
            }
          ],
        },


        {
          id: PortfolioColumn.BidPx,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.current),
          getValue: b => data[b.figi][Side.bid].price.currentString,
          Cell: (b, { selected, value }) => cell(value, selected, isHyUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
          Header: sort => header(gct(PortfolioColumn.BidPx), sort, isHyUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'price', 'current'),
          tdCss,
          printColumns: [
            {
              id: PortfolioColumn.BidPxTime,
              getValue: (b) => data[b.figi][Side.bid].price.currentTime,
            }
          ],
        },
        {
          id: PortfolioColumn.OfferPx,
          getPrintValue: b => priceOrString(data[b.figi][Side.offer].price.current),
          getValue: b => data[b.figi][Side.offer].price.currentString,
          Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#0A0B11]'),
          Header: sort => header(gct(PortfolioColumn.OfferPx), sort, 'bg-[#0A0B11]'),
          sort: createDataSort(Side.offer, 'price', 'current'),
          tdCss,
        },
        {
          id: PortfolioColumn.BidPxChange,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.diff),
          getValue: b => data[b.figi][Side.bid].price.diffString,
          Cell: (b, { selected, value }) => cell(value, selected, getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].price.diff, 'light', 'price')),
          Header: sort => header(gct(PortfolioColumn.BidPxChange), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'price', 'diff'),
          tdCss
        },
        {
          id: PortfolioColumn.BidSpdPrev,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.prev),
          getValue: b => data[b.figi][Side.bid].spread.prevString,
          Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(PortfolioColumn.BidSpdPrev), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'spread', 'prev'),
          tdCss,
          printColumns: [
            {
              id: PortfolioColumn.BidSpdPrevTime,
              getValue: (b) => data[b.figi][Side.bid].spread.prevTime,
            }
          ],
        },
        {
          id: PortfolioColumn.BYTMPrev,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.prev),
          getValue: b => data[b.figi][Side.bid].ytm.prevString,
          Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(PortfolioColumn.BYTMPrev), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'ytm', 'prev'),
          tdCss,
          printColumns: [
            {
              id: PortfolioColumn.BYTMPrevTime,
              getValue: (b) => data[b.figi][Side.bid].ytm.prevTime,
            }
          ],
        },
        {
          id: PortfolioColumn.BidPxPrev,
          getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.prev),
          getValue: b => data[b.figi][Side.bid].price.prevString,
          Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(PortfolioColumn.BidPxPrev), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'price', 'prev'),
          tdCss,
          printColumns: [
            {
              id: PortfolioColumn.BidPxPrevTime,
              getValue: (b) => data[b.figi][Side.bid].price.prevTime,
            }
          ],
        },
      ]

      if (!readonly) {
        // add link column
        columns.unshift({
          tdCss,
          ...getLinkColumnConfig({
            cell,
            header,
            items: positions,
            selectedItems: linkedFigis,
            setSelectedItems: setLinkedFigis
          }),
          id: PortfolioColumn.Link,
        })

        // add remove column
        columns.push({
          id: PortfolioColumn.Remove,
          printable: false,
          Cell: (b, { selected }) => cell(
            <button
              className="bg-transparent h-[1.5rem] flex flex-col items-center justify-center rounded-full w-[1.5rem]"
            // onClick handled for the entire cell
            >
              <XMarkIcon className="h-[1.25rem] text-[#4384C8] w-[1.25rem]" />
            </button>
            , selected),
          Header: sort => header('', sort),
          onCellClick: (e, bond) => {
            e.stopPropagation();
            if (linkedFigis.has(bond.figi)) {
              remove(linkedFigis);
            } else {
              remove(new Set([bond.figi]));
            }
          },
          tdCss,
          draggable: false,
        })
      }

      return columns;
    },
    [createDataSort, data, linkedFigis, positions, gct, remove, setLinkedFigis, isIgUiMode, isHyUiMode, readonly]
  );

  const trCss = useMemo(() =>
    (b: Position & Bond) =>
      classNames(
        linkedFigis.has(b.figi)
          ? 'bg-gradient-to-r from-[#5D5F9D77]'
          : '',
        'rounded-[0.625rem] hover:shadow-[-0.375rem_-0.375rem_1.875rem_0_#615EFF66,0.625rem_0.625rem_1.875rem_0_#07011F59]'
      )
    , [linkedFigis])

  return (
    <TableWithUserPreferences
      columns={columns}
      generateItemKey={b => b.cusip}
      initialSortColumn={PortfolioColumn.Maturity}
      initialSortDirection='ascending'
      items={positions}
      onRowClick={(_, bond) => void selectBond(bond)}
      selectedItem={selectedBond}
      tableCss='mx-[0.625rem]'
      tableName='p'
      theadCss='bg-[#333557] sticky top-0 z-[2]'
      trCss={trCss}
      sortPriorityClassName="mx-2.5"
      getColumnFilterLabel={gct}
      tableRef={tableRef}
      columnsOrderConfig={ColumnsOrderConfig}
      preferenceTableName='portfolio'
      noDataMessage={NO_POSITIONS_FOR_FILTERS}
      useSearchPagination
    />
  );
}

export default PortfolioTable;
