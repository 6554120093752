import { FunctionComponent } from "react";
import { classNames } from "../..";
import { Bond, Side } from '../data/dataProvider';
import { getAsymptoticSpectrumBackground } from "../components/spectrum";
import { BondCardData } from "../components/data/bondCardData";
import { Col, DeleteButton, Row } from "../components/itemCard";
import { InferenceResult } from "@hooks/data/useSimpleInferenceData";
import { useUiMode } from "@hooks/useUiMode";
import { PortfolioColumn } from "./portfolio.constants";
import { calculateBps, getColumnTitle, getPositionValue } from "./portfolio.utils";
import clsx from "clsx";
import { Position } from "../data/portfolios";
import { numericFormatter } from "@/utils/number.utils";
import { ONE_MILLION, POSITION_BPS_FORMAT, POSITION_SIZE_FORMAT } from "@/constants";
import { PositionSizeInput } from "./components/positionSizeInput";
import { usePortfolioPrivileges } from "@/contexts/privileges/components/PortfolioPrivilegesProvider";

const PortfolioCard: FunctionComponent<{
  position: Position & Bond,
  inferenceResult: InferenceResult<Position & Bond>;
  remove: (figi: string) => void,
  selected?: boolean
  portfolioNAV: number;
  onPositionChange?: (positionUpd: Partial<Position> & { figi: string }) => void;
}> = ({
  position,
  inferenceResult,
  remove,
  selected,
  portfolioNAV,
  onPositionChange,
}) => {
    const { canEdit } = usePortfolioPrivileges();
    const gct = getColumnTitle('');
    const { isIgUiMode, isHyUiMode } = useUiMode();
    const { data } = inferenceResult;
    const d = data[position.figi];

    // bid spd
    const bidSpdStr = d[Side.bid].spread.currentString;
    const bidSpdChangeStr = d[Side.bid].spread.diffString;
    const bidSpdChange = d[Side.bid].spread.diff;

    // offer spd
    const offerSpdStr = d[Side.offer].spread.currentString;

    // bid yield to maturity
    const bidYTMStr = d[Side.bid].ytm.currentString;
    const bidYTMChangeStr = d[Side.bid].ytm.diffString;
    const bidYTMChange = d[Side.bid].ytm.diff;

    // bid px
    const bidPxStr = d[Side.bid].price.currentString;
    const bidPxChangeStr = d[Side.bid].price.diffString;
    const bidPxChange = d[Side.bid].price.diff;

    // offer px
    const offerPxStr = d[Side.offer].price.currentString;

    // tenor
    const tenor = d[Side.bid].spread.tenor

    // position
    const positionValue = getPositionValue(position, data, false, true);
    const positionValueDelta = getPositionValue(position, data, true, true);
    const positionValueNumber = getPositionValue(position, data);
    const positionBps = calculateBps(positionValueNumber, portfolioNAV)
    const positionBpsFormatted = numericFormatter(positionBps, POSITION_BPS_FORMAT)
    const positionValueDeltaNumber = getPositionValue(position, data, true);
    const positionBpsDelta = calculateBps(positionValueDeltaNumber, portfolioNAV);
    const positionBpsDeltaFormatted = numericFormatter(positionBpsDelta, POSITION_BPS_FORMAT);

    return (
      <div
        className={clsx(
          'bg-[#1F2034] border-2 flex flex-col gap-[0.75rem] items-center max-w-[17.1875rem] min-w-[17.1875rem] pb-[0.625rem] px-[0.625rem] rounded-[0.625rem] w-[17.1875rem] text-[0.875rem]',
          {
            "border-[#4384C8]": selected,
            "border-transparent": !selected,
            'pt-[1.875rem]': canEdit(),
            'pt-[0.625rem]': !canEdit(),
          }
        )}
      >
        {canEdit() && (
          <DeleteButton onClick={e => { /* stop propogation since the card also has an onClick */ e.stopPropagation(); remove(position.figi); }} />
        )}

        <div className="bg-[#0A0B11] w-full p-2 rounded-md">
          <Row stopPropagation>
            <Col
              label={gct(PortfolioColumn.Position)}
              value={
                <PositionSizeInput
                  positionSize={position.size}
                  displayType={canEdit() ? 'input' : 'text'}
                  onChange={(size) => {
                    onPositionChange?.({ figi: position.figi, size });
                  }}
                />
              }
            />
          </Row>
          <Row>
            <Col label={gct(PortfolioColumn.PositionValue)} value={positionValue} />
            <Col label={gct(PortfolioColumn.PositionValueChange)} value={positionValueDelta} end />
          </Row>
          <Row>
            <Col label={gct(PortfolioColumn.PositionBPs)} value={positionBpsFormatted} />
            <Col label={gct(PortfolioColumn.PositionBPsChange)} value={positionBpsDeltaFormatted} end />
          </Row>
        </div>

        <BondCardData bond={position} tenor={tenor} />


        <div className="bg-[#1F2034] flex flex-col gap-[0.75rem] p-[0.625rem] rounded-[0.4375rem] w-full">

          {/* IG mode fields */}
          {isIgUiMode && (
            <>
              <Row>
                <Col label={gct(PortfolioColumn.BidSpd)} value={bidSpdStr} />
                <Col
                  label={gct(PortfolioColumn.BidSpdChange)}
                  value={bidSpdChangeStr}
                  valueClassName={clsx(getAsymptoticSpectrumBackground(bidSpdChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                  box
                  end
                />
              </Row>

              <Row>
                <Col
                  label={gct(PortfolioColumn.OfferSpd)}
                  value={offerSpdStr}
                />
              </Row>

              <Row>
                <Col
                  label={gct(PortfolioColumn.BYTM)}
                  value={bidYTMStr}
                />
                <Col
                  label={gct(PortfolioColumn.BYTMChange)}
                  value={bidYTMChangeStr}
                  valueClassName={clsx(getAsymptoticSpectrumBackground(bidYTMChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                  box
                  end
                />
              </Row>
            </>
          )}

          {/* HY mode fields */}
          {isHyUiMode && (
            <>
              <Row>
                <Col
                  label={gct(PortfolioColumn.BidPx)}
                  value={bidPxStr}
                />
                <Col
                  label={gct(PortfolioColumn.BidPxChange)}
                  value={bidPxChangeStr}
                  valueClassName={clsx(getAsymptoticSpectrumBackground(bidPxChange), "px-[0.625rem] py-[0.375rem] rounded-[0.375rem]")}
                  box
                  end
                />
              </Row>

              <Row>
                <Col
                  label={gct(PortfolioColumn.OfferPx)}
                  value={offerPxStr}
                />
              </Row>
            </>
          )}

        </div>
      </div>
    )
  };

export default PortfolioCard;
