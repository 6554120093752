import { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { DateTime } from "luxon";
import { classNames } from "../..";
import StyledListBox from "./listbox/styledListbox";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const Calendar: FunctionComponent<{
  Date?: (timestamp: number, index: number, selectValue: (v: number) => void, hoveredDate: number | null) => ReactNode,
  dateCss?: string,
  Header?: (v: number | null) => ReactNode,
  headerCss?: string,
  selectValue: (v: number) => void,
  value: number | null
  onClear: () => void
}> = ({
  Date,
  dateCss,
  Header,
  headerCss,
  selectValue,
  onClear,
  value
}) => {
  const [hoveredDate, setHoveredDate] = useState<number | null>(null);
  const currentDateTime = DateTime.local({ zone: 'America/New_York' });
  const currentYear = currentDateTime.year;
  const yearList = useMemo(() => {
    // earliest trade data: 2019-01-02
    const result = [];
    for (let i = 2019; i <= currentYear; i++) {
      result.push(`${i}`);
    }
    return result;
  }, [currentYear]);

  const valueDateTime = value ? DateTime.fromMillis(value, { zone: 'America/New_York' }) : null;
  const [month, setMonth] = useState<number>(valueDateTime ? valueDateTime.month : currentDateTime.month);
  const [year, setYear] = useState<number>(valueDateTime ? valueDateTime.year : currentDateTime.year);
  const timestamps = useMemo(() => {
    const firstDay = DateTime.fromObject({ year, month, day: 1 }, { zone: 'America/New_York' });
    const firstVisibleDay = firstDay.minus({ days: firstDay.weekday });
    const result = [];
    for (let i = 0; i < 42; i++) {
      result.push(firstVisibleDay.plus({ days: i }).toMillis());
    }
    return result;
  }, [month, year]);

  return (
    <div className="bg-[#343558] border-[0.0625rem] border-[#5D5F9D] flex flex-col items-center overflow-hidden px-[0.625rem] py-[0.625rem] rounded-[0.625rem] sm:px-[1rem]">
      { Header ? Header(value) : <></> }
      <div className="border-b border-[#BDBDBD] gap-[0.625rem] grid grid-cols-[auto,1fr,auto] grid-rows-2 place-items-center mb-[0.625rem] pb-[0.625rem] sm:grid-cols-[auto,auto,auto,auto] sm:grid-rows-1">
        <button
          className="col-start-1 row-start-1 row-end-3 sm:row-end-2"
          disabled={(month === 1 && year === 2019) || year < 2019}
          onClick={() => {
            if (month === 1) {
              setMonth(12);
              setYear(p => p - 1);
            } else {
              setMonth(p => p - 1);
            } 
          }}
        >
          <ChevronLeftIcon 
            className={classNames(
              "h-[1.375rem] w-[1.375rem]",
              (month === 1 && year === 2019) || year < 2019 ? "text-[#7D7D82]" : "text-[#FBFBFD]"
            )}
          />
        </button>
        <div className="col-start-2 row-start-1 w-[10rem]">
          <StyledListBox
            selectValue={v => setMonth(months.indexOf(v) + 1)}
            value={months[month - 1]}
            values={currentDateTime.year === year ? months.slice(0, currentDateTime.month) : months}
          />
        </div>
        <div className="col-start-2 row-start-2 w-[7rem] sm:col-start-3 sm:row-start-1">
          <StyledListBox
            selectValue={v => {
              if (+v === currentDateTime.year && month > currentDateTime.month) {
                setMonth(currentDateTime.month);
              }
              setYear(+v);
            }}
            value={`${year}`}
            values={yearList}
          />
        </div>
        <button
          className="col-start-3 row-start-1 row-end-3 sm:col-start-4 sm:row-end-2"
          disabled={(month >= currentDateTime.month && year === currentDateTime.year) || year > currentDateTime.year}
          onClick={() => {
            if (month === 12) {
              setMonth(1);
              setYear(p => p + 1);
            } else {
              setMonth(p => p + 1);
            } 
          }}
        >
          <ChevronRightIcon
            className={classNames(
              "h-[1.375rem] w-[1.375rem]",
              (month >= currentDateTime.month && year === currentDateTime.year) || year > currentDateTime.year ? "text-[#7D7D82]" : "text-[#FBFBFD]"
            )}
          />
        </button>
      </div>
      <div className="grid grid-cols-7 grid-rows-7 place-items-center select-none">
        <div className={classNames("text-[#FBFBFD] text-[0.75rem]", headerCss ? headerCss : '')}>SUN</div>
        <div className={classNames("text-[#FBFBFD] text-[0.75rem]", headerCss ? headerCss : '')}>MON</div>
        <div className={classNames("text-[#FBFBFD] text-[0.75rem]", headerCss ? headerCss : '')}>TUE</div>
        <div className={classNames("text-[#FBFBFD] text-[0.75rem]", headerCss ? headerCss : '')}>WED</div>
        <div className={classNames("text-[#FBFBFD] text-[0.75rem]", headerCss ? headerCss : '')}>THU</div>
        <div className={classNames("text-[#FBFBFD] text-[0.75rem]", headerCss ? headerCss : '')}>FRI</div>
        <div className={classNames("text-[#FBFBFD] text-[0.75rem]", headerCss ? headerCss : '')}>SAT</div>
        {
          timestamps.map((timestamp, index) =>
            <div
              className={classNames(
                "flex place-items-center h-full w-full",
                dateCss ? dateCss : ''
              )}
              key={timestamp}
              onMouseEnter={() => setHoveredDate(d => timestamp)}
              onMouseLeave={() => setHoveredDate(d => null)}
            >
              {
                Date
                  ? Date(timestamp, index, selectValue, hoveredDate)
                  : <button
                      className='h-full select-none text-[0.875rem] text-[#FBFBFD] w-full'
                      onClick={() => void selectValue(timestamp)}
                    >
                      <span className={classNames('block h-full text-center truncate w-full', value === timestamp ? 'bg-[#5D5F9D]' : '')}>
                        {DateTime.fromMillis(timestamp, { zone: 'America/New_York' }).day}
                      </span>
                    </button>
              }
            </div>
          )
        }
      </div>
      <div className="flex flex-row gap-[1rem] items-center justify-center pt-[0.125rem]">
        <button
          className={classNames(
            "border-[#2E65A0] border-[0.0625rem] px-[0.625rem] py-[0.375rem] rounded-[0.625rem] text-[0.875rem]",
            !value ? "text-[#7D7D82]" : "text-[#FBFBFD]"
          )}
          disabled={!value}
          onClick={() => void onClear()}
        >
          Clear
        </button>
        <button
          className={classNames(
            "border-[#2E65A0] border-[0.0625rem] px-[0.625rem] py-[0.375rem] rounded-[0.625rem] text-[0.875rem]",
            currentDateTime.month === month && currentDateTime.year === year ? "text-[#7D7D82]" : "text-[#FBFBFD]"
          )}
          disabled={currentDateTime.month === month && currentDateTime.year === year}
          onClick={() => { setMonth(currentDateTime.month); setYear(currentDateTime.year); }}
        >
          Go to Today
        </button>
      </div>
    </div>
  );
}

export default Calendar;
