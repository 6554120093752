import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth.slice";
import { baseApi } from "./api";
import { alertReducer } from "./slices/alert.slice";
import { confirmationDialogReducer } from "./slices/confirmationDialog.slice";
import { shareModalReducer } from "./slices/shareModal.slice";

const combinedReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  confirmationDialog: confirmationDialogReducer,
  shareModal: shareModalReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;
