import { FunctionComponent, useContext, useMemo } from "react";
import { DateTime } from "luxon";
import { DataContext } from "../data/dataProvider";
import { classNames } from "../..";
import Calendar from "./calendar";
import { getEndOfDayMs, getFinraEarlyCloseSet, getFinraHolidaySet } from "../../utils/date.utils";

const TradeDateCalendar: FunctionComponent<{
  selectValue: (v: number | null) => void,
  value: number | null
}> = ({
  selectValue,
  value
}) => {

  const { finraHolidays } = useContext(DataContext);
  const holidaySet = useMemo(() => getFinraHolidaySet(finraHolidays), [finraHolidays]);
  const earlyCloseSet = useMemo(() => getFinraEarlyCloseSet(finraHolidays), [finraHolidays]);

  const valueStartOfDayMs = useMemo(() => value ? DateTime.fromMillis(value, { zone: 'America/New_York' }).startOf('day').toMillis() : null, [value]);

  return (
    <Calendar
      Date={(v, i, s) => {
        const dateTime = DateTime.fromMillis(v, { zone: 'America/New_York' });
        const currentDateTime = DateTime.local({ zone: 'America/New_York' });
        return !finraHolidays
          ? <div className="flex h-[2rem] items-center justify-center m-[0.0625rem] rounded-full select-none text-[#5D5F9D] text-center w-[2rem] sm:h-[2.5rem] sm:m-[0.25rem] sm:w-[2.5rem]">{dateTime.day}</div>
          : (dateTime.weekday <= 5 && !holidaySet.has(v) && (
              dateTime < currentDateTime.startOf('day') || // before today
              currentDateTime > dateTime.set({ hour: 18 } || // at least 1 hour after normal market close
              ( earlyCloseSet.has(v) && currentDateTime > DateTime.fromISO(finraHolidays.early_close[dateTime.toISODate()!]).plus({ hour: 1 })) // at least 1 hour after early market close
            )))
            ? <button
                className={classNames(
                  'flex h-[2rem] items-center justify-center m-[0.0625rem] rounded-full select-none w-[2rem] sm:h-[2.5rem] sm:m-[0.25rem] sm:w-[2.5rem]',
                  ((i < 7 && dateTime.day >= 21) || (i > 27 && dateTime.day <= 14)) && v !== valueStartOfDayMs ? "text-[#7D7D82]" : "text-[#FBFBFD]",
                  v === valueStartOfDayMs ? 'bg-[#8183B3]' : '',
                  v === currentDateTime.startOf('day').toMillis() ? 'border border-[#BDBDBD]' : '',
                )}
                onClick={() => s(v)}
              >
                {dateTime.day}
              </button>
            : <div
                className={classNames(
                  "flex h-[2rem] items-center justify-center m-[0.0625rem] rounded-full select-none text-[#7D7D82] text-center w-[2rem] sm:h-[2.5rem] sm:m-[0.25rem] sm:w-[2.5rem]",
                  v === currentDateTime.startOf('day').toMillis() ? 'border border-[#BDBDBD]' : '',
                  holidaySet.has(v) ? 'bg-[#8183B3]/[0.1]' : ''
                )}
                title={holidaySet.has(v) ? 'Holiday' : undefined}
              >
                {dateTime.day}
              </div>
      }}
      headerCss="py-[0.25rem]"
      selectValue={v => void selectValue(
        v
          ? getEndOfDayMs(v, finraHolidays!, earlyCloseSet)
          : null
      )}
      value={valueStartOfDayMs}
      onClear={() => selectValue(null)}
    />
  );
};

export default TradeDateCalendar;
