import { FunctionComponent } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../..';
import ListBox from './listbox';
import clsx from 'clsx';

export const quantityOptions = [
  '0.001',
  '0.01',
  '0.1',
  '0.25',
  '0.5',
  '1',
  '2',
  '3',
  '4',
  '5'
 ] as const;

const TableQuantityListBox: FunctionComponent<{
  selectValue: (v: (typeof quantityOptions)[number]) => void,
  value: (typeof quantityOptions)[number]
  disabled?: boolean;
}> = 
({ selectValue, value, disabled }) => {
  return (
    <ListBox
      disabled={disabled}
      button={props =>
        <div className={clsx('bg-[#8183B3] font-medium leading-[1.17rem] px-[0.3125rem] rounded-[0.3125rem] text-[#0A0B11] text-[1rem] text-right w-[5.5rem]', {
          'cursor-not-allowed': disabled
        })}>
          {props.value} MM
        </div>
      }
      option={(value, props) =>
        <>
          <div className={`${props.selected ? 'font-medium' : 'font-normal'}`}>
            {value} MM
          </div>
          {props.selected ? (
            <span className="absolute inset-y-0 left-0 flex items-center pl-[0.25rem]">
              <CheckIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />
            </span>
          ) : null}
        </>
      }
      optionCss={() => 'bg-[#5D5F9D] border-t-[0.0625rem] border-[#8183B3] cursor-default pl-[1.6rem] pr-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0] hover:bg-[#484A7A]'}
      optionsCss={classNames('absolute -left-[1.125rem] max-h-[7.875rem] mt-[0.1875rem] origin-top-left overflow-auto rounded-[0.625rem] w-[7.75rem] z-[1]')}
      selectValue={selectValue}
      value={value}
      values={quantityOptions}
    />
  );
}

export default TableQuantityListBox;
