import { ColumnConfigMapType, PriceType, UIMode } from "@/types/types";
import { Side } from "../data/api";

export const NO_RUN_BONDS_FOR_FILTERS = 'No bonds found matching filter criteria';

export enum RunColumn {
  Link = 'Link',
  Alert = 'Alert',
  Cusip = 'Cusip',
  Ticker = 'Ticker',
  Coupon = 'Coupon',
  Maturity = 'Maturity',
  AmountOutstanding = 'AmountOutstanding',
  SAndPRating = 'SAndPRating',
  Tenor = 'Tenor',
  Series = 'Series',
  BOSpd = 'BOSpd', // Bid/Offer Spread
  BidPercentile = 'BidPercentile',
  OfferPercentile = 'OfferPercentile',
  BidSpd = 'BidGSpd',
  BidSpdTime = 'BidGSpdTime',
  OfferSpd = 'OfferGSpd',
  BYTM = 'BYTM',
  OfferYTM = 'OfferYTM',
  BidPx = 'BidPx',
  BidPxTime = 'BidPxTime',
  OfferPx = 'OfferPx',
  BidSize = 'BSz',
  OfferSize = 'OSz',
  BidSpdChange = 'BidGSpdChange',
  BYTMChange = 'BYTMChange',
  BidPxChange = 'BidPxChange',
  BidSpdPrev = 'BidGspdPrev',
  BidSpdPrevTime = 'BidGspdPrevTime',
  BYTMPrev = 'BYTMPrev',
  BYTMPrevTime = 'BYTMPrevTime',
  BidPxPrev = 'BidPxPrev',
  BidPxPrevTime = 'BidPxPrevTime',
  Remove = 'Remove',
}

// text for column titles
export const RunColumnTitle: Record<RunColumn, string> = {
  [RunColumn.Link]: '',
  [RunColumn.Alert]: '',
  [RunColumn.Cusip]: 'Cusip',
  [RunColumn.Ticker]: 'Ticker',
  [RunColumn.Coupon]: 'Coupon',
  [RunColumn.Maturity]: 'Maturity',
  [RunColumn.AmountOutstanding]: 'Outstanding',
  [RunColumn.SAndPRating]: 'S&P',
  [RunColumn.Tenor]: 'Benchmark',
  [RunColumn.Series]: 'Series',
  [RunColumn.BOSpd]: 'B/O Spd',
  [RunColumn.BidPercentile]: 'Bid%',
  [RunColumn.OfferPercentile]: 'Offer%',
  [RunColumn.BidSpd]: 'Bid Spread',
  [RunColumn.BidSpdTime]: 'Bid Spread Time',
  [RunColumn.OfferSpd]: 'O Spread',
  [RunColumn.BYTM]: 'B YTM',
  [RunColumn.OfferYTM]: 'O YTM',
  [RunColumn.BidPx]: 'Bid Px',
  [RunColumn.BidPxTime]: 'Bid Px Time',
  [RunColumn.OfferPx]: 'Offer Px',
  [RunColumn.BidSize]: 'BSz',
  [RunColumn.OfferSize]: 'OSz',
  [RunColumn.BidSpdChange]: 'Bid Spread Chng',
  [RunColumn.BYTMChange]: 'B YTM Chng',
  [RunColumn.BidPxChange]: 'Bid Px Chng',
  [RunColumn.BidSpdPrev]: 'Bid Spread {prevPeriod}',
  [RunColumn.BidSpdPrevTime]: 'Bid Spread Previous Time',
  [RunColumn.BYTMPrev]: 'B YTM {prevPeriod}',
  [RunColumn.BYTMPrevTime]: 'B YTM Previous Time',
  [RunColumn.BidPxPrev]: 'Bid Px {prevPeriod}',
  [RunColumn.BidPxPrevTime]: 'Bid Px Previous Time',
  [RunColumn.Remove]: '',
}

export const RunColumnPrintTitle: Record<RunColumn, string> = {
  ...RunColumnTitle,
  [RunColumn.BidSpdPrev]: 'Bid Spread Previous',
  [RunColumn.BYTMPrev]: 'B YTM Previous',
  [RunColumn.BidPxPrev]: 'Bid Px Previous',
}

export const IGRunColumnOrder: RunColumn[] = [
  RunColumn.Link,
  RunColumn.Alert,
  RunColumn.Cusip,
  RunColumn.Ticker,
  RunColumn.Coupon,
  RunColumn.Maturity,
  RunColumn.SAndPRating,
  RunColumn.Series,
  RunColumn.AmountOutstanding,
  RunColumn.Tenor,
  RunColumn.BOSpd,
  RunColumn.BidPercentile,
  RunColumn.OfferPercentile,
  RunColumn.BidSpd,
  RunColumn.OfferSpd,
  RunColumn.BYTM,
  RunColumn.OfferYTM,
  // RunColumn.BidPx,
  // RunColumn.OfferPx,
  RunColumn.BidSize,
  RunColumn.OfferSize,
  RunColumn.BidSpdChange,
  RunColumn.BYTMChange,
  // RunColumn.BidPxChange,
  RunColumn.BidSpdPrev,
  RunColumn.BYTMPrev,
  // RunColumn.BidPxPrev,
  RunColumn.Remove,
]

export const HYRunColumnOrder: RunColumn[] = [
  RunColumn.Link,
  RunColumn.Alert,
  RunColumn.Cusip,
  RunColumn.Ticker,
  RunColumn.Coupon,
  RunColumn.Maturity,
  RunColumn.SAndPRating,
  RunColumn.Series,
  RunColumn.AmountOutstanding,
  RunColumn.BOSpd,
  RunColumn.BidPercentile,
  RunColumn.OfferPercentile,
  RunColumn.BidPx,
  RunColumn.OfferPx,
  // RunColumn.BYTM,
  // RunColumn.OfferYTM,
  // RunColumn.BidSpd,
  // RunColumn.OfferSpd,
  RunColumn.BidSize,
  RunColumn.OfferSize,
  RunColumn.BidPxChange,
  // RunColumn.BYTMChange,
  // RunColumn.BidSpdChange,
  RunColumn.Remove,
]


export const ColumnsOrderConfig: Record<UIMode, RunColumn[]> = {
  [UIMode.InvestmentGrade]: IGRunColumnOrder,
  [UIMode.HighYield]: HYRunColumnOrder,
  [UIMode.Default_14_05_2024]: [],
}

export const ColumnConfigMap: ColumnConfigMapType<RunColumn> = {
  [RunColumn.Link]: null,
  [RunColumn.Alert]: null,
  [RunColumn.Cusip]: null,
  [RunColumn.Ticker]: null,
  [RunColumn.Coupon]: null,
  [RunColumn.Maturity]: null,
  [RunColumn.SAndPRating]: null,
  [RunColumn.Series]: null,
  [RunColumn.Tenor]: null,
  [RunColumn.AmountOutstanding]: null,
  [RunColumn.BOSpd]: null,
  [RunColumn.BidPercentile]: null,
  [RunColumn.OfferPercentile]: null,
  [RunColumn.BidSpd]: { side: Side.bid, priceType: PriceType.Spread },
  [RunColumn.BidSpdTime]: null,
  [RunColumn.OfferSpd]: { side: Side.offer, priceType: PriceType.Spread},
  [RunColumn.BYTM]: { side: Side.bid, priceType: PriceType.Ytm },
  [RunColumn.OfferYTM]: { side: Side.offer, priceType: PriceType.Ytm },
  [RunColumn.BidPx]: { side: Side.bid, priceType: PriceType.Price },
  [RunColumn.BidPxTime]: null,
  [RunColumn.OfferPx]: { side: Side.offer, priceType: PriceType.Price },
  [RunColumn.BidSize]: null,
  [RunColumn.OfferSize]: null,
  [RunColumn.BidSpdChange]: { side: Side.bid, priceType: PriceType.Spread },
  [RunColumn.BYTMChange]: { side: Side.bid, priceType: PriceType.Ytm },
  [RunColumn.BidPxChange]: { side: Side.bid, priceType: PriceType.Price },
  [RunColumn.BidSpdPrev]: { side: Side.bid, priceType: PriceType.Spread },
  [RunColumn.BidSpdPrevTime]: null,
  [RunColumn.BYTMPrev]: { side: Side.bid, priceType: PriceType.Ytm },
  [RunColumn.BYTMPrevTime]: null,
  [RunColumn.BidPxPrev]: { side: Side.bid, priceType: PriceType.Price },
  [RunColumn.BidPxPrevTime]: null,
  [RunColumn.Remove]: null,
}

export const ShortCopyToClipboardColumns: Record<UIMode, RunColumn[]> = {
  [UIMode.Default_14_05_2024]: [],
  [UIMode.HighYield]: [
    RunColumn.Cusip,
    RunColumn.BidPx,
    RunColumn.OfferPx,
  ],
  [UIMode.InvestmentGrade]: [
    RunColumn.Cusip,
    RunColumn.BidSpd,
    RunColumn.OfferSpd,
  ]
}

export const BidOfferCopyToClipboardColumns: Record<UIMode, RunColumn[]> = {
  [UIMode.Default_14_05_2024]: [],
  [UIMode.HighYield]: [
    RunColumn.BidPx,
    RunColumn.OfferPx,
  ],
  [UIMode.InvestmentGrade]: [
    RunColumn.BidSpd,
    RunColumn.OfferSpd,
  ]
}

export enum RunWorkerEventType {
  AddInferences = 'AddInferences',
  ClearInferences = 'ClearInferences',
  CalculateData = 'CalculateData',

  // from worker
  BatchStart = 'BatchStart',
  BatchResult = 'BatchResult',
  CalcCompleted = 'CalcCompleted',
  CalcError = 'CalcError',
}