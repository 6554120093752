import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { ArrowTrendingUpIcon, PlusIcon } from '@heroicons/react/20/solid';
import { classNames } from '../..';
import { AtsIndicator, Bond, Inference, Side, useDataContext } from '../data/dataProvider';
import useInferences, { GetInference } from '../data/useInferences';
import GlobalPercentileListbox from '../components/listbox/globalPercentileListbox';
import AddToPortfolio from '../portfolio/addToPortfolio';
import AddToRun from '../run/addToRun';
import AddAlertButton from '../alert/addAlertButton';
import { AlertTriggeredIcon } from '../alert/alertTriggeredIcon';
import { useOpenPage } from '../../hooks/useOpenPage';
import { FormatNumber } from '../components/formatNumber/formatNumber';
import clsx from 'clsx';
import { useUiMode } from '@hooks/useUiMode';
import { PriceType, UIMode } from '@/types/types';
import { useBondsInferenceRequests } from '@/hooks/data/useBondsInferenceRequests';
import { RfqLabelMap } from './bond.constants';
import { formatAmountOutstanding } from '@/utils/number.utils';
import { BondProbabilityDetails } from './bondProbabilityDetails';
import { getQuantileIndexV2 } from '../data/data.utils';
import { CircleButton } from '../components/buttons/CircleButton';
import { FaFileAlt } from 'react-icons/fa';
import { NotesDrawer } from './components/notesDrawer/notesDrawer';
import { UseBondObjectResult } from '../data/bonds';

// <Change />
const Change: FunctionComponent<{
  className?: string,
  current: Inference | undefined,
  prevEod: Inference | undefined,
  type: PriceType;
  side: Side,
  withDropdown?: boolean,
}> = ({ className, current, prevEod, type, side, withDropdown }) => {
  const { bidPercentile, dealerPercentile, offerPercentile } = useDataContext();
  const percentile = side === Side.bid ? bidPercentile : side === Side.dealer ? dealerPercentile : offerPercentile;
  const quantileIndex = getQuantileIndexV2(percentile, side, type);


  const prev = prevEod ? prevEod[type]?.[quantileIndex] : null;
  const _current = current ? current[type]?.[quantileIndex] : null;
  const diff = typeof prev === 'number' && typeof _current === 'number' ? _current - prev : null;
  const diffToFormat = typeof diff === 'number' ? Math.abs(diff) : null;

  const _type = type === PriceType.Spread ? 'spread_decimals' : type;

  const suffix = type === PriceType.Spread ? <span className='text-[0.625rem]'>bps</span> : '';

  return (
    <div className={classNames("flex flex-col w-full md:w-[7.5rem]", className || '')}>
      <div className="mb-[0.625rem] w-full">
        {withDropdown ? <GlobalPercentileListbox multiline={true} side={side} /> : <div className="md:h-[2.6875rem]" />}
      </div>
      <div className={classNames(prevEod ? "" : "invisible", "leading-[1.17] text-[0.875rem] text-[#8183B3] text-right")}>
        <FormatNumber type={_type} value={prev ?? 0} />{suffix}
      </div>
      <div
        className={clsx("border-b-[0.25rem] leading-[1.17] mb-[0.3125rem] text-[0.875rem] text-[#8183B3] text-right sm:text-[1.5rem]", {
          "border-b-[#3FDA54]": side === Side.bid,
          "border-b-[#FBFBFD]": side === Side.dealer,
          "border-b-[#E25270]": side === Side.offer,
        })}
      >
        <div className="float-left">{diff && diff > 0 ? '+' : diff && diff < 0 ? '-' : ''}</div>
        <span className={classNames(diff === null ? "invisible" : "")}>
          <FormatNumber type={_type} value={diffToFormat ?? 0} />{suffix}
        </span>
      </div>
      <div className={classNames(current ? "" : "invisible", "leading-[1.17] text-[0.875rem] text-[#FBFBFD] text-right sm:text-[1.5rem]")}>
        <FormatNumber type={_type} value={_current ?? 0} />{suffix}
      </div>
    </div>
  );
}

type ItemConfig = { side: Side, type: PriceType, withDropdown?: boolean }
type ItemsConfigMap = { [key in UIMode]: ItemConfig[] }

// <ChangeContainer />
const ChangeContainer = memo(({
  bond,
  quantity,
  getInference,
}: {
  bond: Bond,
  quantity: number,
  getInference: GetInference
}) => {
  const { uiMode } = useUiMode()
  const itemsConfig: ItemsConfigMap = {
    [UIMode.Default_14_05_2024]: [
      { side: Side.bid, type: PriceType.Price, withDropdown: true, },
      { side: Side.dealer, type: PriceType.Price, withDropdown: true },
      { side: Side.offer, type: PriceType.Price, withDropdown: true },
    ],
    [UIMode.HighYield]: [
      { side: Side.bid, type: PriceType.Price, withDropdown: true, },
      { side: Side.dealer, type: PriceType.Price, withDropdown: true },
      { side: Side.offer, type: PriceType.Price, withDropdown: true },
      { side: Side.bid, type: PriceType.Ytm },
      { side: Side.bid, type: PriceType.Spread },
    ],
    [UIMode.InvestmentGrade]: [
      { side: Side.bid, type: PriceType.Spread, withDropdown: true },
      { side: Side.dealer, type: PriceType.Spread, withDropdown: true },
      { side: Side.offer, type: PriceType.Spread, withDropdown: true },
      { side: Side.bid, type: PriceType.Ytm },
      { side: Side.bid, type: PriceType.Price },
    ],
  }
  const items = itemsConfig[uiMode];

  const classes = [
    'row-start-2 col-start-1',
    'row-start-2 col-start-2',
    'row-start-2 col-start-3',
    'row-start-3 md:row-start-2 col-start-1 md:col-start-4',
    'row-start-3 md:row-start-2 col-start-2 md:col-start-5',
  ]

  return (
    <>
      {items.map(({ side, type, withDropdown }, idx) => {
        return (
          <Change
            key={`${side}-${type}`}
            className={classes[idx]}
            current={getInference(bond.figi, AtsIndicator.N, quantity, side, type)}
            prevEod={getInference(bond.figi, AtsIndicator.N, quantity, side, type, true)}
            side={side}
            withDropdown={withDropdown}
            type={type}
          />
        )
      })}

      <div className='row-start-2 col-start-4 mt-0 hidden md:block'>
        <BondProbabilityDetails 
          figi={bond.figi}
          quantity={quantity}
          getInference={getInference}
        />
      </div>
    </>
  )
})

// <BondSummary />
const BondSummary: FunctionComponent<{ 
  bond: Bond, 
  links: boolean, 
  quantity: number 
  bondObjectApiRes: UseBondObjectResult
}> = ({ 
  bond, 
  links, 
  quantity,
  bondObjectApiRes,
}) => {
  const openPage = useOpenPage();
  const { uiMode, isIgUiMode } = useUiMode();
  const [notesOpen, setNotesOpen] = useState(false);

  const rfqLabelMap = RfqLabelMap[uiMode];
  const bonds = useMemo(() => [bond], [bond]);
  const getQuantity = useCallback(() => quantity, [quantity]);
  const inferenceRequests = useBondsInferenceRequests({
    bonds,
    rfqLabelMap,
    getQuantity,
  });

  const [getInference] = useInferences(inferenceRequests);
  const priceInference = getInference(bond.figi, AtsIndicator.N, quantity, Side.bid, PriceType.Spread);
  const formattedTenor = typeof priceInference?.tenor === 'number' ? `${priceInference.tenor}Y` : '-';

  const notesButton = (
    <CircleButton onClick={() => setNotesOpen(true)}>
      <FaFileAlt fontSize={14} />
    </CircleButton>
  )

  return (
    <div className="flex flex-col items-center w-full">
      <div className="bg-[#333557] justify-between flex gap-[0.625rem] items-start  p-[0.625rem] pt-[1.125rem] rounded-t-[0.625rem] w-full sm:pl-[1.25rem] md:pl-[2.5rem]">
        <div className="gap-x-[0.625rem] grid grid-cols-[auto,auto,auto,auto] sm:grid-cols-[auto,auto,auto,auto,auto,auto,auto,auto]">
          <div className='flex flex-col border-r border-[#5D5F9D] pr-[0.625rem]'>
            <div className="leading-[0.75rem] row-start-1 text-[0.75rem] text-[#8183B3]">Ticker</div>
            <button className="row-start-2 text-[0.875rem] text-left lg:text-[1.5rem]" onClick={() => void openPage.issuer(bond)} >{bond.ticker}</button>
          </div>
          <div className='flex flex-col border-r border-[#5D5F9D] pr-[0.625rem]'>
            <div className="leading-[0.75rem] text-[0.75rem] text-[#8183B3]">Coupon</div>
            <div className="text-[0.875rem] lg:text-[1.5rem]">{bond.coupon.toFixed(3)}</div>
          </div>
          <div className='flex flex-col border-r border-[#5D5F9D] pr-[0.625rem]'>
            <div className="leading-[0.75rem]  text-[0.75rem] text-[#8183B3]">Maturity</div>
            <div className="text-[0.875rem] lg:text-[1.5rem]">{bond.maturity}</div>
          </div>
          <div className='flex flex-col sm:border-r sm:border-[#5D5F9D] pr-[0.625rem]'>
            <div className="leading-[0.75rem] text-[0.75rem] text-[#8183B3]">Series</div>
            <div className="text-[0.875rem] lg:text-[1.5rem]">{bond.series || '-'}</div>
          </div>

          <div className='flex flex-col border-r border-[#5D5F9D] pr-[0.625rem] mt-[0.625rem] sm:mt-0'>
            <div className="leading-[0.75rem]  text-[0.75rem] text-[#8183B3]">S&P</div>
            <div className=" text-[0.875rem] lg:text-[1.5rem]">{bond.rating || '-'}</div>
          </div>

          {isIgUiMode && (
            <div className='flex flex-col border-r border-[#5D5F9D] pr-[0.625rem] mt-[0.625rem] sm:mt-0'>
              <div className="leading-[0.75rem]  text-[0.75rem] text-[#8183B3]">Benchmark</div>
              <div className=" text-[0.875rem] lg:text-[1.5rem]">{formattedTenor}</div>
            </div>
          )}

          <div className='flex flex-col border-r border-[#5D5F9D] pr-[0.625rem] mt-[0.625rem] sm:mt-0'>
            <div className="leading-[0.75rem]  text-[0.75rem] text-[#8183B3]">Outstanding</div>
            <div className=" text-[0.875rem] lg:text-[1.5rem]">{formatAmountOutstanding(bond.amountOutstanding)}</div>
          </div>

          <div className='flex flex-col  pr-[0.625rem] mt-[0.625rem] sm:mt-0'>
            <div className="leading-[0.75rem]  text-[0.75rem] text-[#8183B3]">CUSIP</div>
            <div className="text-[0.875rem] lg:text-[1.5rem]">
              <div className="flex items-center gap-2">
                {bond.cusip} <AlertTriggeredIcon cusip={bond.cusip} width={24} />
              </div>
            </div>
          </div>
        </div>

        {/* Notes button */}
        <div className='hidden sm:flex'>
          {notesButton}
        </div>

      </div>
      <div className="bg-[#333557] flex gap-[0.625rem] p-[0.625rem] w-full flex-wrap sm:pl-[0.875rem] md:pl-[2rem] lg:py-[0.875rem]">
        <div><AddToPortfolio figi={bond.figi} /></div>
        <div><AddToRun figi={bond.figi} /></div>
        <div><AddAlertButton figi={bond.figi} /></div>
        <div className='flex items-center md:hidden'>
          <BondProbabilityDetails 
            figi={bond.figi}
            quantity={quantity}
            getInference={getInference}
          />
        </div>
        <div className='flex items-center sm:hidden'>
          {notesButton}
        </div>
      </div>
      <div className="bg-[#0A0B11] flex flex-col items-start pl-[0.625rem] py-[1.25rem] rounded-b-[0.625rem] w-full sm:pl-[1.25rem] md:pl-[2.5rem]">
        <div className={clsx("gap-x-[1.25rem] grid grid-cols-[auto_auto_auto] md:grid-cols-[auto_auto_auto_auto_auto] grid-rows-[auto,auto,auto] w-full md:w-auto", {
          "gap-y-[1.25rem]": links,

        })}>
          {!links ? <></> :
            <div className="col-start-1 col-end-4 flex flex-row items-center justify-between row-start-1 w-full">
              <button className="bg-[#5D5F9D] h-[2.25rem] flex flex-col items-center justify-center rounded-full w-[2.25rem]">
                <PlusIcon className="h-[1.25rem]" />
              </button>
              <button className="bg-[#BFC5F1] h-[2.25rem] flex flex-col items-center justify-center rounded-[0.625rem] w-[4.625rem]"
                onClick={() => void openPage.bond(bond)}
              >
                <ArrowTrendingUpIcon className="h-[1.25rem] text-[#333557]" />
              </button>
            </div>
          }

          <ChangeContainer
            bond={bond}
            quantity={quantity}
            getInference={getInference}
          />

        </div>
      </div>


      {/* NotesDrawer */}
      <NotesDrawer 
        open={notesOpen} 
        onClose={() => setNotesOpen(false)} 
        bondObjectApiRes={bondObjectApiRes}
      />
    </div>
  );
}

export default BondSummary;
