import { DateTime } from 'luxon';
import styles from './DateTimeField.module.scss'
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

export const DateTimeField = ({
  value,
  min,
  max,
  fullWidth,
  onChange,
}: {
  value?: number | null;
  min?: number;
  max?: number;
  fullWidth?: boolean;
  onChange: (d: number) => void;
}) => {
  const [innerValue, setInnerValue] = useState('');
  const lastChangeValueRef = useRef<number | undefined>(undefined);

  function getFormattedTime(t?: number | null) {
    return t ? DateTime.fromMillis(t, { zone: 'America/New_York' }).toFormat('yyyy-LL-dd|T')?.replace('|', 'T') : ""
  }

  const _min = getFormattedTime(min)
  const _max = getFormattedTime(max)

  useEffect(() => {

    if (lastChangeValueRef.current !== value) {
      const _value = getFormattedTime(value)
      setInnerValue(_value)
    }
  }, [value, setInnerValue])



  return (
    <input
      type="datetime-local"
      value={innerValue}
      min={_min}
      max={_max}
      className={clsx(styles.input, {
        [styles.fullWidth]: fullWidth
      })}
      onChange={e => {
        setInnerValue(e.target.value)

        if (!e.target.value) {
          return;
        }

        const v = e.target.value.replace('T', '|')
        let newValueMs = DateTime.fromFormat(v, 'yyyy-LL-dd|T', { zone: 'America/New_York' }).toMillis()

        if (Number.isNaN(newValueMs)) {
          return;
        }

        if (min && newValueMs < min) {
          newValueMs = min
        }
        
        if (max && newValueMs > max) {
          newValueMs = max
        }

        lastChangeValueRef.current = newValueMs
        onChange(newValueMs)
      }}
      onBlur={(e) => {
        // set value to the last valid value
        setInnerValue(getFormattedTime(value));
      }}
    />
  )
}