import { Side } from "@/app/data/api";

export type TableName = 'search' | 'issuer' | 'run' | 'portfolio' | 'targetAlert' | 'volatilityAlert';
type ColumnsOrder = `${UIMode}`

export type UserPreferences = {
  version: string;
  uiMode: UIMode;
  tableConfig?: {
    [key in TableName]?: {
      columnsOrder?: ColumnsOrderMap;
    }
  }
}

export type UserPreferencesApiResponse = {
  version: string;
  uiMode: UIMode;
  tableConfig?: string; // JSON string of table config `UserPreferences.tableConfig`
}

export type ColumnsOrderMap = {
  [key in ColumnsOrder]?: {
    order: string[];
    defaultOrder: string[]; // we use default order to calculate which columns were added and add them to the columns order config
  };
}

export enum UIMode{
  Default_14_05_2024 = 'default-14.05.2024', // this one is not used, keep for historical reasons
  HighYield = 'HighYield', // $ price
  InvestmentGrade = 'InvestmentGrade', // spread price
}

export enum PriceType {
  Price = 'price',
  Spread = 'spread',
  Ytm = 'ytm',
}

export type TableColumnConfigItem = {
  side: Side;
  priceType: PriceType;
}

export type TableColumnConfig = TableColumnConfigItem | TableColumnConfigItem[]

export type ColumnConfigMapType<T extends string> = Record<T, TableColumnConfig | null>

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
  ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
  : `${Key}`;
}[keyof ObjectType & (string | number)];

export type RfqLabelMap = Record<Side, PriceType[]>

export type Note = {
  id?: string;
  createdAt: string;
  text: string;
}