import { ReactNode, useCallback, useMemo } from 'react';
import { classNames } from "../..";
import { Bond, Side, useDataContext } from '../data/dataProvider';
import { Column, Sort, TableSortIcon } from '../components/table/table';
import { getPriceAsymptoticSpectrumBackground } from '../components/spectrum';
import { CusipCell } from '../components/data/cusipCell';
import { AlertCell } from '../components/data/alertCell';
import { AlertHeaderCell } from '../components/data/alertHeaderCell';
import { ColumnsOrderConfig, IssuerColumn, NO_BONDS_FOR_FILTERS } from './issuer.constants';
import { InferenceResult } from '@hooks/data/useSimpleInferenceData';
import { useUiMode } from '@hooks/useUiMode';
import { getColumnTitle } from './issuer.utils';
import { TableWithUserPreferences } from '@components/table/tableWithUserPreferences';
import { usePreviousMs } from '../components/filters/hooks/useFilters';
import { createNumberSort, createStringSort, sortByMaturity } from '@/utils/sort.utils';
import { getAsymptoticSpectrumBackground } from '../components/spectrum';
import { getCheckboxColumnConfig } from '../components/data/table.utils';
import clsx from 'clsx';
import { formatAmountOutstanding, formatCoupon } from '@/utils/number.utils';
import { PercentilesInfoCellContent } from '../components/data/percentilesInfoCellContent';
import { PriceType } from '@/types/types';

const tdCss = (selected: boolean) => classNames(selected ? 'bg-[#5D5F9D77]' : '', 'px-[0.3125rem] first:rounded-l-[0.625rem] last:rounded-r-[0.625rem] group-hover:bg-[#5D5F9D] group-hover:text-[#FBFBFD]');

const header = (name: ReactNode, sort?: Sort, background?: string, sortable: boolean = true) =>
  <div className="border-[#5D5F9D] border-b-[0.0625rem] h-full px-[0.3125rem] w-full">
    <div className={classNames(background || '', 'flex flex-col h-full items-start justify-center px-[0.625rem] py-[0.625rem] rounded-t-[0.625rem] text-[0.875rem] text-[#C9CADE] text-start')}>
      <div className={clsx('gap-[0.4375rem]  place-items-center', {
        'grid grid-cols-[1fr,auto]': sortable,
        'flex': !sortable,
      })}>
        <div className="whitespace-nowrap font-bold">{name}</div>
        {sortable && <TableSortIcon sort={sort} />}
      </div>
    </div>
  </div>

const nonSortableHeader = (name: ReactNode,  background?: string) => {
  return header(name, undefined, background, false);
}

const cell = (contents: ReactNode, selected: boolean, background?: string) =>
  <div className={classNames(background && !selected ? `${background} group-hover:bg-[transparent]` : '', 'flex flex-col h-full justify-center px-[0.625rem] py-[1rem] text-[0.875rem]')}>
    {contents}
  </div>


const IssuerTable = ({
  bonds,
  inferenceResult,
  selectBond,
  selectedBond,
}: {
  bonds: Bond[],
  inferenceResult: InferenceResult<Bond>;
  selectBond: (bond: Bond) => void
  selectedBond?: Bond | null,
}) => {
  const { dispatch, selectedBonds } = useDataContext();
  const { getFormattedPreviousDate } = usePreviousMs();
  const previousString = getFormattedPreviousDate();
  const gct = useCallback((c: IssuerColumn) => getColumnTitle(previousString)(c), [previousString]);
  const { data, createDataSort } = inferenceResult;
  const { isIgUiMode, isHyUiMode } = useUiMode();

  const columns: Column<Bond, IssuerColumn>[] = useMemo(
    () => {
      const columns: Column<Bond, IssuerColumn>[] = [
        {
          ...getCheckboxColumnConfig({
            cell,
            header: (t, s) => nonSortableHeader(t, s),
            items: bonds,
            selectedItems: selectedBonds,
            setSelectedItems: (newSelection) => dispatch({ type: 'setSelectedBonds', payload: newSelection }),
            getKey: (b) => b['figi'],
            onlySelectAll: true,
          }),
          id: IssuerColumn.SelectCheckbox,
          tdCss,
        },
        {
          id: IssuerColumn.Alert,
          Cell: (b, { selected }) => cell(<AlertCell cusip={b.cusip} />, selected),
          Header: () => nonSortableHeader(<AlertHeaderCell />),
          tdCss,
          draggable: false,
        },
        {
          id: IssuerColumn.Cusip,
          Cell: (b, { selected }) => cell(<CusipCell cusip={b.cusip} />, selected),
          Header: sort => header(gct(IssuerColumn.Cusip), sort),
          sort: createStringSort('cusip'),
          tdCss
        },
        {
          id: IssuerColumn.Ticker,
          Cell: (b, { selected }) => cell(b['ticker'], selected),
          Header: sort => header(gct(IssuerColumn.Ticker), sort),
          sort: createStringSort('ticker'),
          tdCss
        },
        {
          id: IssuerColumn.Coupon,
          Cell: (b, { selected }) => cell(formatCoupon(b['coupon']), selected),
          Header: sort => header(gct(IssuerColumn.Coupon), sort),
          sort: (a, b) => a['coupon'] - b['coupon'],
          tdCss
        },
        {
          id: IssuerColumn.Maturity,
          Cell: (b, { selected }) => cell(b['maturity'], selected),
          Header: sort => header(gct(IssuerColumn.Maturity), sort),
          sort: sortByMaturity,
          tdCss
        },
        {
          id: IssuerColumn.SAndPRating,
          Cell: (b, { selected }) => cell(b['rating'] || '-', selected),
          Header: sort => header(gct(IssuerColumn.SAndPRating), sort),
          sort: createStringSort('rating'),
          tdCss
        },
        {
          id: IssuerColumn.Series,
          Cell: (b, { selected }) => cell(b['series'] || '-', selected),
          Header: sort => header(gct(IssuerColumn.Series), sort),
          sort: (a, b) => a['series'] > b['series'] ? 1 : a['series'] < b['series'] ? -1 : 0,
          tdCss
        },
        {
          id: IssuerColumn.Tenor,
          getValue: b => {
            const priceType = isIgUiMode ? PriceType.Spread : PriceType.Price;
            const tenor = data[b.figi][Side.bid][priceType].tenor;
            return typeof tenor === 'number' ? `${tenor}Y` : '-';
          },
          Cell: (b, { selected, value }) => cell(value, selected),
          Header: sort => header(gct(IssuerColumn.Tenor), sort),
          sort: isIgUiMode 
            ? createDataSort(Side.bid, 'spread', 'tenor')
            : createDataSort(Side.bid, 'price', 'tenor'),
          tdCss
        },
        {
          id: IssuerColumn.AmountOutstanding,
          getValue: b => formatAmountOutstanding(b.amountOutstanding),
          Cell: (b, {selected, value}) => cell(value, selected),
          Header: sort => header(gct(IssuerColumn.AmountOutstanding), sort),
          sort: createNumberSort('amountOutstanding'),
          tdCss
        },
        {
          id: IssuerColumn.Percentiles,
          printable: false,
          Cell: (b, {selected}) => cell(
            <PercentilesInfoCellContent data={data[b.figi]} />,
            selected,
          ),
          Header: sort => header(gct(IssuerColumn.Percentiles), sort),
          onCellClick: e => e.stopPropagation(),
          tdCss,
        },
        {
          id: IssuerColumn.BidSpd,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].spread.currentString, selected, isIgUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
          Header: sort => header(gct(IssuerColumn.BidSpd), sort, isIgUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'spread', 'current'),
          tdCss
        },
        {
          id: IssuerColumn.OfferSpd,
          Cell: (b, { selected }) => cell(data[b.figi][Side.offer].spread.currentString, selected, 'bg-[#0A0B11]'),
          Header: sort => header(gct(IssuerColumn.OfferSpd), sort, 'bg-[#0A0B11]'),
          sort: createDataSort(Side.offer, 'spread', 'current'),
          tdCss
        },
        {
          id: IssuerColumn.BidSpdChange,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].spread.diffString, selected, getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].spread.diff, 'light', 'spread')),
          Header: sort => header(gct(IssuerColumn.BidSpdChange), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'spread', 'diff'),
          tdCss
        },
        {
          id: IssuerColumn.BYTM,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].ytm.currentString, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(IssuerColumn.BYTM), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'ytm', 'current'),
          tdCss
        },
        {
          id: IssuerColumn.BYTMChange,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].ytm.diffString, selected, getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].ytm.diff, 'light', 'ytm')),
          Header: sort => header(gct(IssuerColumn.BYTMChange), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'ytm', 'diff'),
          tdCss
        },
        {
          id: IssuerColumn.BidPx,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].price.currentString, selected, isHyUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
          Header: sort => header(gct(IssuerColumn.BidPx), sort, isHyUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'price', 'current'),
          tdCss
        },
        {
          id: IssuerColumn.OfferPx,
          Cell: (b, { selected }) => cell(data[b.figi][Side.offer].price.currentString, selected, 'bg-[#0A0B11]'),
          Header: sort => header(gct(IssuerColumn.OfferPx), sort, 'bg-[#0A0B11]'),
          sort: createDataSort(Side.offer, 'price', 'current'),
          tdCss
        },
        {
          id: IssuerColumn.BidPxChange,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].price.diffString, selected, getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].price.diff, 'light', 'price')),
          Header: sort => header(gct(IssuerColumn.BidPxChange), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'price', 'diff'),
          tdCss
        },
        {
          id: IssuerColumn.BidSpdPrev,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].spread.prevString, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(IssuerColumn.BidSpdPrev), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'spread', 'prev'),
          tdCss
        },
        {
          id: IssuerColumn.BYTMPrev,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].ytm.prevString, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(IssuerColumn.BYTMPrev), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'ytm', 'prev'),
          tdCss
        },
        {
          id: IssuerColumn.BidPxPrev,
          Cell: (b, { selected }) => cell(data[b.figi][Side.bid].price.prevString, selected, 'bg-[#484A7A]'),
          Header: sort => header(gct(IssuerColumn.BidPxPrev), sort, 'bg-[#484A7A]'),
          sort: createDataSort(Side.bid, 'price', 'prev'),
          tdCss
        },
      ]

      return columns;
    },
    [bonds, createDataSort, data, dispatch, gct, selectedBonds, isIgUiMode, isHyUiMode]
  );

  const trCss = useMemo(() =>
    (b: Bond) =>
      classNames(
        selectedBonds.has(b.figi)
          ? 'bg-gradient-to-r from-[#5D5F9D77]'
          : '',
        'rounded-[0.625rem] hover:shadow-[-0.375rem_-0.375rem_1.875rem_0_#615EFF66,0.625rem_0.625rem_1.875rem_0_#07011F59]'
      )
    , [selectedBonds])

  return (
    <TableWithUserPreferences
      columns={columns}
      generateItemKey={b => b.cusip}
      initialSortColumn={IssuerColumn.Maturity}
      initialSortDirection='ascending'
      items={bonds}
      onRowClick={(_, bond) => void selectBond(bond)}
      tableCss='mx-[0.625rem]'
      selectedItem={selectedBond}
      tableName='i'
      theadCss='bg-[#333557] sticky top-0 z-[2]'
      trCss={trCss}
      getColumnFilterLabel={gct}
      columnsOrderConfig={ColumnsOrderConfig}
      preferenceTableName='issuer'
      noDataMessage={NO_BONDS_FOR_FILTERS}
      useSearchPagination
    />
  );
}

export default IssuerTable;
