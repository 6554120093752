import { useState } from "react";
import { RangePicker } from "@/app/components/rangePicker";
import ActionModal from "@/app/components/modal/actionModal";
import { DateField } from "@/app/components/fields/date/DateField";
import { DateTime } from "luxon";
import { DateTimeField } from "@/app/components/fields/date/DateTimeField/DateTimeField";

export const CustomTimePeriodDrawer = ({
  open,
  onClose,
  onApply,
  maxDate,
  minDate,
}: {
  open: boolean;
  onClose: () => void;
  onApply: (startDate: number, endDate: number) => void;
  maxDate?: number;
  minDate?: number;
}) => {
  const now = DateTime.now().setZone('America/New_York').toMillis()
  const initialEndDate = Math.min(now, maxDate || Infinity);
  const initialStartDate = DateTime.fromMillis(initialEndDate, { zone: 'America/New_York' }).minus({ days: 2 }).startOf('day').toMillis();
  
  const [startDate, setStartDate] = useState<number | null>(initialStartDate);
  const [endDate, setEndDate] = useState<number | null>(initialEndDate);

  const buttonDisabled = !startDate || !endDate || startDate > endDate;


  return (
    <ActionModal
      show={open}
      onClose={onClose}
      overflow="visible"
      size="content"
      disabled={buttonDisabled}
      actionName="Apply"
      title="Custom Time Period"
      action={() => {
        if (buttonDisabled) return;

        onApply(startDate, endDate);
      }}
      body={(
        <div className="sm:w-[28rem]">

          {/* period start  */}
          <div className="flex items-center gap-[0.625rem]">

            <div className="flex  flex-col gap-[0.625rem] w-fit mb-5 mx-auto">
              <DateTimeField 
                value={startDate} 
                min={minDate} 
                max={maxDate} 
                onChange={(v) => setStartDate(v)}
              />
              <DateTimeField 
                value={endDate} 
                min={minDate} 
                max={maxDate} 
                onChange={(v) => setEndDate(v)}
              />
            </div>
          </div>

          <RangePicker
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDate}
            minDate={minDate}
            onChange={([startDate, endDate]) => {
              setStartDate(startDate);

              const _endDate = endDate ? DateTime.fromMillis(endDate, { zone: 'America/New_York' }).endOf('day').toMillis() : endDate
              setEndDate(_endDate);
            }}
          />

        </div>
      )}
    />
  )
}