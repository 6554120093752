import { useContext, useMemo } from "react";
import { DateTime } from "luxon";
import { DataContext } from "../data/dataProvider";
import Calendar from "./calendar";
import { getFinraEarlyCloseSet, getFinraHolidaySet } from "../../utils/date.utils";
import clsx from "clsx";

export type RangeValue = [number | null, number | null];

export const RangePicker = ({
  startDate,
  endDate,
  maxDate = Infinity,
  minDate = -Infinity,
  onChange,
}: {
  startDate: number | null;
  endDate: number | null;
  maxDate?: number;
  minDate?: number;
  onChange: (value: RangeValue) => void;
}) => {

  const { finraHolidays } = useContext(DataContext);
  const holidaySet = useMemo(() => getFinraHolidaySet(finraHolidays), [finraHolidays]);
  const earlyCloseSet = useMemo(() => getFinraEarlyCloseSet(finraHolidays), [finraHolidays]);


  const valueStartOfDayMs = useMemo(() => startDate ? DateTime.fromMillis(startDate, { zone: 'America/New_York' }).startOf('day').toMillis() : null, [startDate]);

  return (
    <Calendar
      headerCss="py-[0.25rem]"
      selectValue={date => { 
        if (startDate && endDate) {
          onChange([date, null]);
        } else if (startDate) {
          if (date < startDate) {
            onChange([date, startDate]);
          } else {
            onChange([startDate, date]);
          }
        } else {
          onChange([date, null]);
        }
      }}
      value={valueStartOfDayMs}
      onClear={() => onChange([null, null])}

      // render date cell
      Date={(timestamp, idx, selectValue, hoveredDate) => {
        const dateTime = DateTime.fromMillis(timestamp, { zone: 'America/New_York' });
        const currentDateTime = DateTime.local({ zone: 'America/New_York' });

        if (!finraHolidays) {
          return <div className="flex h-[2rem] items-center justify-center m-[0.0625rem] rounded-full select-none text-[#5D5F9D] text-center w-[2rem] sm:h-[2.5rem] sm:m-[0.25rem] sm:w-[2.5rem]">{dateTime.day}</div>
        }

        const isSelectable = timestamp <= maxDate && timestamp >= minDate;
        const condition = dateTime.weekday <= 5 && !holidaySet.has(timestamp) && (
          dateTime < currentDateTime.startOf('day') || // before today
          currentDateTime > dateTime.set({ hour: 18 } || // at least 1 hour after normal market close
            (earlyCloseSet.has(timestamp) && currentDateTime > DateTime.fromISO(finraHolidays.early_close[dateTime.toISODate()!]).plus({ hour: 1 })) // at least 1 hour after early market close
          ))

        const isToday = timestamp === currentDateTime.startOf('day').toMillis();
        const _endDate = endDate || hoveredDate;
        const isPartOfTheRange = !startDate 
          ? timestamp === hoveredDate
          : _endDate 
            ? _endDate < startDate
              ? timestamp >= _endDate && timestamp <= startDate
              : timestamp >= startDate && timestamp <= _endDate
            : false;

        if (condition && isSelectable) {
          const unknownCondition = ((idx < 7 && dateTime.day >= 21) || (idx > 27 && dateTime.day <= 14)) && timestamp !== valueStartOfDayMs;
          const darkText = unknownCondition && !isPartOfTheRange;

          return (
            <button
              type="button"
              className={clsx(
                'flex h-[2rem] items-center justify-center m-[0.0625rem] rounded-full select-none w-[2rem] sm:h-[2.5rem] sm:m-[0.25rem] sm:w-[2.5rem]',
                {
                  'bg-[#8183B3]': isPartOfTheRange, 
                  'border border-[#BDBDBD]': isToday,
                  'text-[#7D7D82]': darkText,
                  'text-[#FBFBFD]': !darkText
                }
              )}
              onClick={() => selectValue(timestamp)}
            >
              {dateTime.day}
            </button>
          )
        }

        const isHoliday = holidaySet.has(timestamp);
        return (
          <div
            className={clsx(
              "flex h-[2rem] items-center justify-center m-[0.0625rem] rounded-full select-none text-center w-[2rem] sm:h-[2.5rem] sm:m-[0.25rem] sm:w-[2.5rem]",
              {
                'border border-[#BDBDBD]': isToday,
                'cursor-pointer': isSelectable,
                'bg-[#8183B3]/[0.1]': isHoliday && !isPartOfTheRange,
                'bg-[#8183B3] text-[#FBFBFD]': isPartOfTheRange && isSelectable,
                'text-[#7D7D82]': !isPartOfTheRange || !isSelectable,
              }
            )}
            title={isHoliday ? 'Holiday' : undefined}
            onClick={() => {
              if (isSelectable) {
                selectValue(timestamp);
              }
            }}
          >
            {dateTime.day}
          </div>
        )
      }}
    />
  );
};

