import { Fragment, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../..';
import clsx from 'clsx';

export type MenuItem = {
  ariaLabel: string;
  Item: () => ReactNode; // render item contents
  itemCss?: ({ active, disabled }: { active: boolean, disabled: boolean }) => string; // optional css applied to item
  key: string; // unique key for this option
  disabled?: boolean; // is this option disabled
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // onClick handler for button
};

type StyledMenuProps = {
  ariaLabel: string;
  Button: () => ReactNode; // render button contents
  buttonCss?: string; // optional css applied to button
  menuCss?: string; // optional css applied to the Menu
  menuItems: MenuItem[];
  menuItemsCss?: string; // optional css applied to Menu.Items
};

const StyledMenu = ({
  ariaLabel,
  buttonCss,
  menuCss,
  menuItems,
  menuItemsCss,
  Button
}: StyledMenuProps) =>
  <Menu
    as='div'
    className={classNames(
      'relative',
      menuCss ? menuCss : ''
    )}
  >
    <Menu.Button
      aria-label={ariaLabel}
      onClick={e => e.stopPropagation()}
      className={classNames(
        "p-[0.625rem] rounded-[0.625rem]",
        buttonCss ? buttonCss : ''
      )}
    >
      { Button() }
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={classNames(
          "absolute mt-[0.25rem] origin-top-right overflow-hidden right-0 rounded-[0.3125rem] w-[9rem] z-[3] focus:outline-none",
          menuItemsCss ? menuItemsCss : ''
        )}
      >
        {
          menuItems.map(item =>
            <Menu.Item
              aria-label={item.ariaLabel}
              as='button'
              disabled={item.disabled}
              className={({ active, disabled }) => clsx(
                active ? 'bg-[#484A7A]' : 'bg-[#5D5F9D]',
                'block px-[0.625rem] py-[0.4375rem] text-left text-[0.875rem] w-full',
                item.itemCss ? item.itemCss({ active, disabled }) : '',
                {
                  'text-[#FBFBFD]': !item.disabled,
                  'cursor-not-allowed text-[#FBFBFD5E]': item.disabled,
                }
              )}
              key={item.key}
              onClick={item.onClick}
            >
              { item.Item() }
            </Menu.Item>
          )
        }
      </Menu.Items>
    </Transition>
  </Menu>;

export default StyledMenu;
