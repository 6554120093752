import { ReactNode, useCallback, useMemo } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from "../..";
import { Bond, Side } from '../data/dataProvider';
import { RunBond } from '../data/runs';
import { Column, Sort, TableProps, TableSortIcon } from '../components/table/table';
import TablePercentileListBox from '../components/listbox/tablePercentileListBox';
import TableQuantityListBox, { quantityOptions } from '../components/listbox/tableQuantityListBox';
import { getPriceAsymptoticSpectrumBackground } from '../components/spectrum';
import { CusipCell } from '../components/data/cusipCell';
import { AlertCell } from '../components/data/alertCell';
import { AlertHeaderCell } from '../components/data/alertHeaderCell';
import { getLinkColumnConfig } from '../components/data/table.utils';
import { ColumnsOrderConfig, NO_RUN_BONDS_FOR_FILTERS, RunColumn } from './run.constants';
import { formatAmountOutstanding, priceOrString } from '@/utils/number.utils';
import { useUiMode } from '@hooks/useUiMode';
import { InferenceResult } from '@hooks/data/useSimpleInferenceData';
import { getColumnTitle, isPercentileSelectionDisabled } from './run.utils';
import { TableWithUserPreferences } from '../components/table/tableWithUserPreferences';
import { usePreviousMs } from '../components/filters/hooks/useFilters';
import { createNumberSort, createStringSort, sortByMaturity } from '@/utils/sort.utils';
import { BOSpreadValue, TableBidOfferSpreadListBox } from '../components/listbox/tableBidOfferSpreadListBox';
import { PriceType, UIMode } from '@/types/types';
import { FaCircleInfo } from 'react-icons/fa6'
import { Tooltip } from '@components/tooltip';
import { WarningTooltip } from '../components/warningTooltip';
import { RunMaxProbabilityResultPreview } from './components/runMaxProbabilityResultPreview';
import { Percentile } from '@/constants';

const tdCss = (selected: boolean) => classNames(selected ? 'bg-[#5D5F9D77]' : '', 'px-[0.3125rem] first:rounded-l-[0.625rem] last:rounded-r-[0.625rem] group-hover:bg-[#5D5F9D] group-hover:text-[#FBFBFD]');

const header = (name: ReactNode, sort?: Sort, background?: string, infoText?: ReactNode) =>
  <div className="border-[#5D5F9D] border-b-[0.0625rem] h-full px-[0.3125rem] w-full">
    <div className={classNames(background || '', 'flex flex-col h-full items-start justify-center px-[0.625rem] py-[0.625rem] rounded-t-[0.625rem] text-[0.875rem] text-[#C9CADE] text-start')}>
      <div className="gap-[0.4375rem] flex justify-center items-center">
        <div className='whitespace-nowrap font-bold'>{name}</div>
        {infoText && (
          <Tooltip tooltip={infoText} maxWidthCss='max-w-[20.625rem]' delayShow={350}>
            <FaCircleInfo onClick={(e) => e.stopPropagation()} />
          </Tooltip>
        )}
        <TableSortIcon sort={sort} />
      </div>
    </div>
  </div>
const cell = (contents: ReactNode, selected: boolean, background?: string) =>
  <div className={classNames(background && !selected ? `${background} group-hover:bg-[transparent]` : '', 'flex flex-col h-full justify-center px-[0.625rem] py-[1rem] text-[0.875rem]')}>
    {contents}
  </div>



const ONE_MILLION = 1000000;

const RunTable = ({
  inferenceResult,
  bonds,
  linkedFigis,
  remove,
  selectedBond,
  selectBond,
  setLinkedFigis,
  setRunBonds,
  readonly,
  tableRef,
}: {
  inferenceResult: InferenceResult<RunBond & Bond>;
  bonds: (RunBond & Bond)[],
  linkedFigis: Set<string>,
  remove: (figiSet: Set<string>) => void,
  selectedBond?: (RunBond & Bond) | null,
  selectBond: (bond: RunBond & Bond) => void,
  setLinkedFigis: React.Dispatch<React.SetStateAction<Set<string>>>
  setRunBonds: React.Dispatch<React.SetStateAction<(RunBond & Bond)[] | null | undefined>>
  readonly?: boolean;
  tableRef?: TableProps<RunBond & Bond, RunColumn>['tableRef'];
}) => {
  const { getFormattedPreviousDate } = usePreviousMs();
  const previousString = getFormattedPreviousDate();
  const gct = useCallback((c: RunColumn) => getColumnTitle(previousString)(c), [previousString]);
  const { isIgUiMode, isHyUiMode, uiMode } = useUiMode();
  const { data, createDataSort } = inferenceResult;

  const createSelectValueHandler = useMemo(() =>
    (updateBond: (bond: Bond & RunBond) => Bond & RunBond) =>
      (b: Bond & RunBond) => {
        if (linkedFigis.has(b.figi)) {
          setRunBonds([
            ...bonds.filter(bond => !linkedFigis.has(bond.figi)),
            ...bonds.filter(bond => linkedFigis.has(bond.figi)).map(updateBond)
          ]);
        } else {
          setRunBonds([...bonds.filter(bond => bond.figi !== b.figi), updateBond(b)]);
        }
      }
    , [bonds, linkedFigis, setRunBonds]);

  const columns: Column<RunBond & Bond, RunColumn>[] = useMemo(() => {

    const getPercentileValue = (side: Side) => (b: Bond & RunBond) => {
      const value = side === Side.bid ? b.bidPercentile : b.offerPercentile;

      const priceType = uiMode === UIMode.InvestmentGrade ? PriceType.Spread : PriceType.Price

      const boSpread = b.bidOfferSpread?.[uiMode];
      const v = typeof boSpread === 'number' || boSpread === 'Profit'
        ? data[b.figi][side][priceType].data?.maxProbability || 0
        : value

      return v;
    }


    const columns: Column<RunBond & Bond, RunColumn>[] = [
      {
        id: RunColumn.Alert,
        printable: false,
        draggable: false,
        Cell: (b, { selected }) => cell(<AlertCell cusip={b.cusip} />, selected),
        Header: () => header(<AlertHeaderCell />),
        tdCss,
      },
      {
        id: RunColumn.Cusip,
        getValue: b => b.cusip,
        Cell: (b, { selected, value }) => cell(<CusipCell cusip={value as string} />, selected),
        Header: sort => header(gct(RunColumn.Cusip), sort),
        sort: (a, b) => a['cusip'] > b['cusip'] ? 1 : a['cusip'] < b['cusip'] ? -1 : 0,
        tdCss,
      },
      {
        id: RunColumn.Ticker,
        getValue: b => b.ticker,
        Cell: (b, { selected, value }) => cell(value, selected),
        Header: sort => header(gct(RunColumn.Ticker), sort),
        sort: (a, b) => a['ticker'] > b['ticker'] ? 1 : a['ticker'] < b['ticker'] ? -1 : 0,
        tdCss,
      },
      {
        id: RunColumn.Coupon,
        getValue: b => b['coupon'].toFixed(3),
        Cell: (b, { selected, value }) => cell(`${value}%`, selected),
        Header: sort => header(gct(RunColumn.Coupon), sort),
        sort: (a, b) => a['coupon'] - b['coupon'],
        tdCss,
      },
      {
        id: RunColumn.Maturity,
        getValue: b => b.maturity,
        Cell: (b, { selected, value }) => cell(value, selected),
        Header: sort => header(gct(RunColumn.Maturity), sort),
        sort: sortByMaturity,
        tdCss
      },
      {
        id: RunColumn.SAndPRating,
        getValue: b => b.rating,
        Cell: (b, { selected }) => cell(b['rating'] || '-', selected),
        Header: sort => header(gct(RunColumn.SAndPRating), sort),
        sort: createStringSort('rating'),
        tdCss
      },
      {
        id: RunColumn.Series,
        getValue: b => b.series,
        Cell: (b, { selected, value }) => cell(value || '-', selected),
        Header: sort => header(gct(RunColumn.Series), sort),
        sort: (a, b) => a['series'] > b['series'] ? 1 : a['series'] < b['series'] ? -1 : 0,
        tdCss
      },
      {
        id: RunColumn.Tenor,
        getValue: b => {
          const priceType = isIgUiMode ? PriceType.Spread : PriceType.Price;
          const tenor = data[b.figi][Side.bid][priceType].tenor;
          return typeof tenor === 'number' ? `${tenor}Y` : '-';
        },
        Cell: (b, { selected, value }) => cell(value, selected),
        Header: sort => header(gct(RunColumn.Tenor), sort),
        sort: isIgUiMode 
          ? createDataSort(Side.bid, 'spread', 'tenor')
          : createDataSort(Side.bid, 'price', 'tenor'),
        tdCss
      },
      {
        id: RunColumn.AmountOutstanding,
        getValue: b => formatAmountOutstanding(b.amountOutstanding),
        Cell: (b, { selected, value }) => cell(value, selected),
        Header: sort => header(gct(RunColumn.AmountOutstanding), sort),
        sort: createNumberSort('amountOutstanding'),
        tdCss
      },
      {
        id: RunColumn.BOSpd,
        getValue: (b) => {
          return b.bidOfferSpread?.[uiMode] ?? 'Percentiles'
        },
        Cell: (b, { selected, value }) => {
          const type = value === 'Profit'
            ? 'profit' as const
            : typeof value === 'number'
              ? 'probability' as const
              : 'quantiles' as const

          const priceType = isIgUiMode ? PriceType.Spread : PriceType.Price;
          const error = data[b.figi][Side.bid][priceType].data?.error;
          const calcData = data[b.figi][Side.bid][priceType].data?.data;

          return cell(
            <div className='flex items-center gap-2'>
              <TableBidOfferSpreadListBox
                value={value as BOSpreadValue}
                disabled={readonly}
                selectValue={v => void createSelectValueHandler(bond => {
                  return {
                    ...bond,
                    bidOfferSpread: {
                      ...bond.bidOfferSpread,
                      [uiMode]: v === 'Percentiles' ? null : v
                    }
                  }
                })(b)}
              />
              {error && (
                <WarningTooltip id={`bospread-error-${b.figi}`} className='text-[#FB275a] w-5'>
                  {error}
                </WarningTooltip>
              )}
              {!error && (
                <RunMaxProbabilityResultPreview 
                  data={calcData as any}
                  type={type} 
                  inferenceData={data[b.figi]}
                />
              )}
              
            </div>
            , selected
          )
        },
        Header: sort => header(
          gct(RunColumn.BOSpd),
          sort,
          undefined,
          <>
            <p className='mb-4 text-[#C9CADE]'>Select how the bid/offer spread is generated.</p>

            <p className='mb-4'>
              <strong className='font-medium'>Percentile:</strong>
              {' '}
              <span className='text-[#C9CADE]'>User selects the bid and offer percentiles. Those percentiles are rounded, if rounding is enabled, to form the bid and offer prices displayed</span>
            </p>

            <p className='mb-4'>
              <strong className='font-medium'>Numeric Values:</strong>
              {' '}
              <span className='text-[#C9CADE]'>If you select one of the numeric values, you are fixing the Bid / Offer Spread, i.e., the difference between the bid and offer. Then, the bid and offers are chosen to maximize the joint probability of crossing a buy and sell trade at the bid and offer.</span>
            </p>

            <p className='mb-4'>
              <strong className='font-medium'>Profit:</strong>
              {' '}
              <span className='text-[#C9CADE]'>The bid and offer are automatically chosen to maximize the expected profit. The expected profit is defined as (Bid / Offer Spread) * (Trade Joint Probability), where the Trade Joint Probability = (1 - bid percentile) * (1 - offer percentile). Disclaimer: the bid and offer percentiles are generated by our machine learning model and don't necessarily reflect the true probability of trading. Therefore the expected profit is not necessarily reflective of the true profit or the true probability of a profit. </span>
            </p>
          </>
        ),
        sort: (a, b) => {
          const aSpread = a.bidOfferSpread?.[uiMode];
          const bSpread = b.bidOfferSpread?.[uiMode];

          if (typeof aSpread === 'number' && typeof bSpread === 'number') {
            return aSpread - bSpread;
          }

          if (typeof aSpread === 'number') {
            return 1;
          }

          if (typeof bSpread === 'number') {
            return -1;
          }

          return 0;
        },
        onCellClick: e => void e.stopPropagation(),
        tdCss
      },
      {
        id: RunColumn.BidPercentile,
        getValue: getPercentileValue(Side.bid),
        Cell: (b, { selected, value }) => {
          const boSpread = b.bidOfferSpread?.[uiMode];
          const disabled = readonly || isPercentileSelectionDisabled(boSpread)
          const disabledReason = readonly 
            ? 'Editing is not allowed'
            : `Change the "${gct(RunColumn.BOSpd)}" column value to "Percentile" to be able to set custom bid/offer percentiles`

          return cell(
            <TablePercentileListBox
              value={`${value}` as Percentile}
              selectValue={v => void createSelectValueHandler(bond => ({ ...bond, bidPercentile: +v }))(b)}
              disabled={disabled}
              disabledTooltipText={disabledReason}
            />
            ,
            selected
          )
        },
        Header: sort => header(gct(RunColumn.BidPercentile), sort),
        sort: (a, b) => a.bidPercentile - b.bidPercentile,
        onCellClick: e => void e.stopPropagation(),
        tdCss
      },
      {
        id: RunColumn.OfferPercentile,
        getValue: getPercentileValue(Side.offer),
        Cell: (b, { selected, value }) => {
          const boSpread = b.bidOfferSpread?.[uiMode];
          const disabled = readonly || isPercentileSelectionDisabled(boSpread)
          const disabledReason = readonly
            ? 'Editing is not allowed'
            : `Change the "${gct(RunColumn.BOSpd)}" column value to "Percentile" to be able to set custom bid/offer percentiles`;
          
          return (
            cell(
              <TablePercentileListBox
                value={`${value}` as Percentile}
                selectValue={v => void createSelectValueHandler(bond => ({ ...bond, offerPercentile: +v }))(b)}
                disabled={disabled}
                disabledTooltipText={disabledReason}
              />
              , selected)
          )
        },
        Header: sort => header(gct(RunColumn.OfferPercentile), sort),
        sort: (a, b) => a.offerPercentile - b.offerPercentile,
        onCellClick: e => void e.stopPropagation(),
        tdCss
      },
      {
        id: RunColumn.BidSpd,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.current),
        getValue: b => data[b.figi][Side.bid].spread.currentString,
        Cell: (b, { selected, value }) => cell(value, selected, isIgUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.BidSpd), sort, isIgUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'spread', 'current'),
        tdCss,
        printColumns: [
          {
            id: RunColumn.BidSpdTime,
            getValue: (b) => data[b.figi][Side.bid].spread.currentTime,
          }
        ],
      },
      {
        id: RunColumn.OfferSpd,
        getPrintValue: b => priceOrString(data[b.figi][Side.offer].spread.current),
        getValue: b => data[b.figi][Side.offer].spread.currentString,
        Cell: (b, { selected, value }) => cell(value, selected, isIgUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.OfferSpd), sort, isIgUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
        sort: createDataSort(Side.offer, 'spread', 'current'),
        tdCss,
      },

      {
        id: RunColumn.BYTM,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.current),
        getValue: b => data[b.figi][Side.bid].ytm.currentString,
        Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.BYTM), sort, 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'ytm', 'current'),
        tdCss,
      },
      {
        id: RunColumn.OfferYTM,
        getPrintValue: b => priceOrString(data[b.figi][Side.offer].ytm.current),
        getValue: b => data[b.figi][Side.offer].ytm.currentString,
        Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.OfferYTM), sort, 'bg-[#484A7A]'),
        sort: createDataSort(Side.offer, 'ytm', 'current'),
        tdCss,
      },

      {
        id: RunColumn.BidPx,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.current),
        getValue: b => data[b.figi][Side.bid].price.currentString,
        Cell: (b, { selected, value }) => cell(value, selected, isHyUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.BidPx), sort, isHyUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'price', 'current'),
        tdCss,
        printColumns: [
          {
            id: RunColumn.BidPxTime,
            getValue: (b) => data[b.figi][Side.offer].price.currentTime,
          }
        ],
      },
      {
        id: RunColumn.OfferPx,
        getPrintValue: b => priceOrString(data[b.figi][Side.offer].price.current),
        getValue: b => data[b.figi][Side.offer].price.currentString,
        Cell: (b, { selected, value }) => cell(value, selected, isHyUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.OfferPx), sort, isHyUiMode ? 'bg-[#0A0B11]' : 'bg-[#484A7A]'),
        sort: createDataSort(Side.offer, 'price', 'current'),
        tdCss,
      },

      {
        id: RunColumn.BidSize,
        getPrintValue: b => b.bidSize,
        getValue: b => `${b.bidSize / ONE_MILLION}`,
        Cell: (b, { selected, value }) => {
          return cell(
            <TableQuantityListBox
              disabled={readonly}
              value={value as (typeof quantityOptions)[number]}
              selectValue={v => void createSelectValueHandler(bond => ({ ...bond, bidSize: +v * ONE_MILLION }))(b)}
            />, 
            selected, 
            'bg-[#484A7A]'
          )
        },
        Header: sort => header(gct(RunColumn.BidSize), sort, 'bg-[#484A7A]'),
        onCellClick: e => e.stopPropagation(),
        sort: (a, b) => a.offerSize - b.offerSize,
        tdCss
      },
      {
        id: RunColumn.OfferSize,
        getPrintValue: b => b.offerSize,
        getValue: b => `${b.offerSize / ONE_MILLION}`,
        Cell: (b, { selected, value }) =>
          cell(
            <TableQuantityListBox
              disabled={readonly}
              value={value as (typeof quantityOptions)[number]}
              selectValue={v => void createSelectValueHandler(bond => ({ ...bond, offerSize: +v * ONE_MILLION }))(b)}
            />
            , selected, 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.OfferSize), sort, 'bg-[#484A7A]'),
        onCellClick: e => e.stopPropagation(),
        sort: (a, b) => a.offerSize - b.offerSize,
        tdCss
      },
      {
        id: RunColumn.BidSpdChange,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.diff),
        getValue: b => data[b.figi][Side.bid].spread.diffString,
        Cell: (b, { selected, value }) =>
          cell(
            value,
            selected,
            getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].spread.diff, 'light', 'spread')
          ),
        Header: sort => header(gct(RunColumn.BidSpdChange), sort, 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'spread', 'diff'),
        tdCss
      },
      {
        id: RunColumn.BYTMChange,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.diff),
        getValue: b => data[b.figi][Side.bid].ytm.diffString,
        Cell: (b, { selected, value }) =>
          cell(
            value,
            selected,
            getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].ytm.diff, 'light', 'ytm')
          ),
        Header: sort => header(gct(RunColumn.BYTMChange), sort, 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'ytm', 'diff'),
        tdCss
      },
      {
        id: RunColumn.BidPxChange,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.diff),
        getValue: b => data[b.figi][Side.bid].price.diffString,
        Cell: (b, { selected, value }) =>
          cell(
            value,
            selected,
            getPriceAsymptoticSpectrumBackground(data[b.figi][Side.bid].price.diff, 'light', 'price')
          ),
        Header: sort => header(gct(RunColumn.BidPxChange), sort, 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'price', 'diff'),
        tdCss
      },
      {
        id: RunColumn.BidSpdPrev,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].spread.prev),
        getValue: b => data[b.figi][Side.bid].spread.prevString,
        Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.BidSpdPrev), sort, 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'spread', 'prev'),
        tdCss,
        printColumns: [
          {
            id: RunColumn.BidSpdPrevTime,
            getValue: (b) => data[b.figi][Side.bid].spread.prevTime,
          }
        ],
      },
      {
        id: RunColumn.BYTMPrev,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].ytm.prev),
        getValue: b => data[b.figi][Side.bid].ytm.prevString,
        Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.BYTMPrev), sort, 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'ytm', 'prev'),
        tdCss,
        printColumns: [
          {
            id: RunColumn.BYTMPrevTime,
            getValue: (b) => data[b.figi][Side.bid].ytm.prevTime,
          }
        ],
      },
      {
        id: RunColumn.BidPxPrev,
        getPrintValue: b => priceOrString(data[b.figi][Side.bid].price.prev),
        getValue: b => data[b.figi][Side.bid].price.prevString,
        Cell: (b, { selected, value }) => cell(value, selected, 'bg-[#484A7A]'),
        Header: sort => header(gct(RunColumn.BidPxPrev), sort, 'bg-[#484A7A]'),
        sort: createDataSort(Side.bid, 'price', 'prev'),
        tdCss,
        printColumns: [
          {
            id: RunColumn.BidPxPrevTime,
            getValue: (b) => data[b.figi][Side.bid].price.prevTime,
          }
        ],
      },
    ];

    if (!readonly) {
      // link column
      columns.unshift({
        tdCss,
        ...getLinkColumnConfig({
          cell,
          header,
          items: bonds,
          selectedItems: linkedFigis,
          setSelectedItems: setLinkedFigis
        }),
        id: RunColumn.Link,
      },)

      // delete column
      columns.push({
        id: RunColumn.Remove,
        printable: false,
        draggable: false,
        Cell: (b, { selected }) => cell(
          <button
            className="bg-transparent h-[1.5rem] flex flex-col items-center justify-center rounded-full w-[1.5rem]"
          // onClick handled for the entire cell
          >
            <XMarkIcon className="h-[1.25rem] text-[#4384C8] w-[1.25rem]" />
          </button>
          , selected),
        Header: sort => header(gct(RunColumn.Remove), sort),
        onCellClick: (e, bond) => {
          e.stopPropagation();
          if (linkedFigis.has(bond.figi)) {
            remove(linkedFigis);
          } else {
            remove(new Set([bond.figi]));
          }
        },
        tdCss
      })
    }

    return columns;
  }, [bonds, createDataSort, createSelectValueHandler, data, remove, linkedFigis, setLinkedFigis, gct, isIgUiMode, isHyUiMode, uiMode, readonly]);

  const trCss = useMemo(() =>
    (b: Bond & RunBond) =>
      classNames(
        linkedFigis.has(b.figi)
          ? 'bg-gradient-to-r from-[#5D5F9D77]'
          : '',
        'rounded-[0.625rem] hover:shadow-[-0.375rem_-0.375rem_1.875rem_0_#615EFF66,0.625rem_0.625rem_1.875rem_0_#07011F59]'
      )
    , [linkedFigis])



  return (
    <TableWithUserPreferences
      columns={columns}
      generateItemKey={b => b.cusip}
      initialSortColumn={RunColumn.Maturity}
      initialSortDirection='ascending'
      items={bonds}
      onRowClick={(_, bond) => void selectBond(bond)}
      selectedItem={selectedBond}
      tableCss='mx-[0.625rem]'
      tableName='r'
      theadCss='bg-[#333557] sticky top-0 z-[2]'
      trCss={trCss}
      sortPriorityClassName='mx-2.5'
      getColumnFilterLabel={gct}
      tableRef={tableRef}
      columnsOrderConfig={ColumnsOrderConfig}
      preferenceTableName='run'
      noDataMessage={NO_RUN_BONDS_FOR_FILTERS}
      useSearchPagination
      // defaultPageSize={25}
    />
  );
}

export default RunTable;
