import StyledMenu from "@/app/components/styledMenu";
import { usePortfolioPrivileges } from "@/contexts/privileges/components/PortfolioPrivilegesProvider";
import { openShareModal } from "@/store/actions/shareModal.actions";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

export const PortfolioActionsMenu = ({
  portfolioId,
  portfolioName,
  onCopy,
  onImport,
  onDownload,
  onEdit,
  onDelete,
}: {
  portfolioId: string;
  portfolioName: string;
  onCopy: () => void;
  onImport: () => void;
  onDownload: () => void;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const { canEdit } = usePortfolioPrivileges();
  const editDisabled = !canEdit();

  return (
    <StyledMenu
      ariaLabel='Portfolio Menu'
      Button={() => <EllipsisVerticalIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />}
      buttonCss='rounded-full'
      menuItems={[
        {
          ariaLabel: 'Copy to Clipboard',
          Item: () => 'Copy to Clipboard',
          key: 'copy',
          onClick: () => onCopy(),
        },
        {
          ariaLabel: 'Import',
          Item: () => 'Import',
          key: 'import',
          disabled: editDisabled,
          onClick: () => onImport(),
        },
        {
          ariaLabel: 'Download',
          Item: () => 'Download',
          key: 'download',
          onClick: () => onDownload(),
        },
        {
          ariaLabel: 'Edit',
          Item: () => 'Edit',
          key: 'edit',
          disabled: editDisabled,
          onClick: () => onEdit(),
        },
        {
          ariaLabel: 'Share',
          Item: () => 'Share',
          key: 'share',
          disabled: editDisabled,
          onClick: () => {
            openShareModal({
              objectId: portfolioId,
              objectType: 'portfolio',
              objectName: portfolioName,
            })
          }
        },
        {
          ariaLabel: 'Delete',
          Item: () => 'Delete',
          key: 'delete',
          // disabled: editDisabled,
          onClick: () => onDelete()
        }
      ]}
    />
  )
}