import StyledMenu from "@/app/components/styledMenu";
import { useRunPrivileges } from "@/contexts/privileges/components/RunPrivilegesProvider";
import { openShareModal } from "@/store/actions/shareModal.actions";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

export const RunActionsMenu = ({
  runId,
  runName,
  onCopyClick,
  onDelete,
  onDownload,
  onEdit,
}: {
  runId: string;
  runName: string;
  onCopyClick: (type: 'short' | 'bid_offer' | 'default') => void;
  onDelete: () => void;
  onDownload: () => void;
  onEdit: () => void;
}) => {
  const { canEdit } = useRunPrivileges();
  const editDisabled = !canEdit();

  return (
    <StyledMenu
      ariaLabel='Run Menu'
      Button={() => <EllipsisVerticalIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />}
      buttonCss='rounded-full'
      menuItems={[
        {
          ariaLabel: 'Copy to Clipboard',
          Item: () => 'Copy to Clipboard',
          key: 'copy',
          onClick: () => onCopyClick('default'),
        },
        {
          ariaLabel: 'Copy Runs',
          Item: () => 'Copy Runs',
          key: 'copy-runs',
          onClick: () => onCopyClick('short'),
        },
        {
          ariaLabel: 'Copy Bids / Offers',
          Item: () => 'Copy Bids / Offers',
          key: 'copy-runs',
          onClick: () => onCopyClick('bid_offer'),
        },
        {
          ariaLabel: 'Download',
          Item: () => 'Download',
          key: 'download',
          onClick: onDownload,
        },
        {
          ariaLabel: 'Edit',
          Item: () => 'Edit',
          key: 'edit',
          disabled: editDisabled,
          onClick: () => onEdit()
        },
        {
          ariaLabel: 'Share',
          Item: () => 'Share',
          key: 'share',
          disabled: editDisabled,
          onClick: () => {
            openShareModal({
              objectId: runId,
              objectType: 'run',
              objectName: runName,
            })
          }
        },
        {
          ariaLabel: 'Delete',
          Item: () => 'Delete',
          key: 'delete',
          disabled: editDisabled,
          onClick: () => onDelete()
        }
      ]}
    />
  )
}