import { CheckIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../..';
import ListBox from './listbox';
import { useUiMode } from '@/hooks/useUiMode';
import clsx from 'clsx';

export type BOSpreadValue = 'Percentiles' | 'Profit' | number;

export const TableBidOfferSpreadListBox = ({ 
  selectValue, 
  disabled,
  value 
}: {
    selectValue: (v:  BOSpreadValue) => void,
    disabled?: boolean;
    value:  BOSpreadValue
}) => {
  const { isIgUiMode } = useUiMode();
  const options = (isIgUiMode ? BOSpdIGOptions : BOSpdHYOptions) as string[];

  return (
    <ListBox
      disabled={disabled}
      button={props =>
        <div className={clsx('bg-[#8183B3] font-medium leading-[1.17rem] px-[0.3125rem] rounded-[0.3125rem] text-[#0A0B11] text-[1rem] w-[6rem]', {
          'cursor-not-allowed': disabled,
        })}>
          {props.value}
        </div>
      }
      option={(value, props) =>
        <>
          <div className={`${props.selected ? 'font-medium' : 'font-normal'}`}>
            {value}
          </div>
          {props.selected ? (
            <span className="absolute inset-y-0 left-0 flex items-center pl-[0.25rem]">
              <CheckIcon className="h-[1rem] w-[1rem]" aria-hidden="true" />
            </span>
          ) : null}
        </>
      }
      optionCss={() => 'bg-[#5D5F9D] border-t-[0.0625rem] border-[#8183B3] cursor-default pl-[1.6rem] pr-[1rem] py-[0.4375rem] relative select-none text-[0.875rem] text-[#FBFBFD] first:border-t-[0] hover:bg-[#484A7A]'}
      optionsCss={classNames('absolute -left-[1.125rem] max-h-[7.875rem] mt-[0.1875rem] origin-top-left overflow-auto rounded-[0.625rem] w-[7.75rem] z-[1]')}
      selectValue={(v) => {
        const valueNumber = parseFloat(v);
        const value = isNaN(valueNumber) ? v : valueNumber;
        selectValue(value as BOSpreadValue)
      }}
      value={value as string}
      values={options}
    />
  );
}



export const BOSpdHYOptions: BOSpreadValue[] = [
  'Percentiles', 'Profit', 0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1, 1.125, 1.25, 1.375, 1.5, 1.625, 1.75, 1.875, 2, 2.125, 2.25, 2.375, 2.5, 2.625, 2.75, 2.875, 3
]

export const BOSpdIGOptions: BOSpreadValue[] = [
  'Percentiles', 'Profit', 0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50
]